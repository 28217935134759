import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useAuthenticationContext } from '../contexts/AuthenticationContext';
import { useAPIContext } from '../contexts/APIContext';


const MainPage = () => {
  const { employeeAuthenticationData } = useAuthenticationContext();
  const { getJobsCountData,jobsCountData, loading, error } = useAPIContext();
  
  const [isGeneralOpen, setIsGeneralOpen] = useState(employeeAuthenticationData.teamData.name !== "Finance");
  const [isFinanceOpen, setIsFinanceOpen] = useState(employeeAuthenticationData.teamData.name === "Finance");

  const toggleGeneral = () => {
    setIsGeneralOpen(!isGeneralOpen);
  };

  const toggleFinance = () => {
    setIsFinanceOpen(!isFinanceOpen);
  };

  useEffect(() => {
    if (employeeAuthenticationData) {
      const requestBody = {
        "employeeLevel": employeeAuthenticationData.levelData.name,
        "teamName": employeeAuthenticationData.teamData.name,
        "teamId": employeeAuthenticationData.teamData.id,
        "employeeEmail": employeeAuthenticationData.email
      };
      getJobsCountData(requestBody);
    }
  }, [employeeAuthenticationData]);

  useEffect(() => {
      console.log('job data count:', jobsCountData);
  }, [jobsCountData]);

  if (loading) {
    return <div className="p-4 bg-gray-900 text-white min-h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 bg-gray-900 text-white min-h-screen">Error: {error}</div>;
  }

  return (
    <div className="p-4 bg-gray-900 text-white min-h-screen">
      <div className="space-y-6">


        {/* General Section */}
        <div className="space-y-4">
          <button
            onClick={toggleGeneral}
            className="flex items-center w-full p-4 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none"
          >
            <span className="text-lg font-semibold mr-4">General</span>
            <span className="ml-auto">
              {isGeneralOpen ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </button>
          {isGeneralOpen && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              <Link
                to="/job_bag/job_form"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
                Job Form
              </Link>
              <Link
                to="/job_bag/raised_jobs"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
                
                Jobs Raised {loading ? 'loading' : (jobsCountData && jobsCountData[0] ? jobsCountData[0].count : 'N/A')}
              </Link>
              <Link
                to="/job_bag/approved_jobs"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
                Jobs Approved {loading ? 'loading' : (jobsCountData && jobsCountData[1] ? jobsCountData[1].count : 'N/A')}
              </Link>
              <Link
                to="/job_bag/rejected_jobs"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
                Jobs Rejected {loading ? 'loading' : (jobsCountData && jobsCountData[2] ? jobsCountData[2].count : 'N/A')}
              </Link>
            </div>
          )}
        </div>

        {/* Finance Section */}
        <div className="space-y-4">
          <button
            onClick={toggleFinance}
            className="flex items-center w-full p-4 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none"
          >
            <span className="text-lg font-semibold mr-4">Finance</span>
            <span className="ml-auto">
              {isFinanceOpen ? <FaChevronUp /> : <FaChevronDown />}
            </span>
          </button>
          {isFinanceOpen && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              <Link
                to="/job_bag/to_be_assigned_jobs"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
                Jobs To Be Assigned {loading ? 'loading' : (jobsCountData && jobsCountData[3] ? jobsCountData[3].count : 'N/A')}
              </Link>
              <Link
                to="/job_bag/to_be_billed_jobs"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
                Jobs To Be Billed {loading ? 'loading' : (jobsCountData && jobsCountData[4] ? jobsCountData[4].count : 'N/A')}
              </Link>
              <Link
                to="/job_bag/payment_pending"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
               
                Payment Pending {loading ? 'loading' : (jobsCountData && jobsCountData[5] ? jobsCountData[5].count : 'N/A')}
              </Link>
              <Link
                to="/job_bag/payment_completed"
                className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
              >
                Payment Completed {loading ? 'loading' : (jobsCountData && jobsCountData[6] ? jobsCountData[6].count : 'N/A')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPage;




        // {/* All Jobs Section */}
        // {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        //   <Link
        //     to="/job_bag/all_jobs"
        //     className="flex items-center justify-center h-32 bg-gray-700 rounded hover:bg-gray-600 text-center text-white"
        //   >
        //     All Jobs
        //   </Link>
        // </div> */}