import { useState } from 'react';
import { FaEllipsisV, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FilterMenu = ({ onSelectFilters, clients, btcCtc, department, showJobStatusFilter, showDepartmentFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clientMenuOpen, setClientMenuOpen] = useState(false);
  const [btcCtcMenuOpen, setBtcCtcMenuOpen] = useState(false);
  const [departmentMenuOpen, setDepartmentMenuOpen] = useState(false);
  //this is different from the context one which is global, this is local, onSelectFilter is global
  const [selectedFilters, setSelectedFilters] = useState({});

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleNestedMenu = (menuName) => {
    if (menuName === 'Client') {
      setClientMenuOpen(!clientMenuOpen);
    } else if (menuName === 'BTC/CTC') {
      setBtcCtcMenuOpen(!btcCtcMenuOpen);
    } else if (menuName === 'Department') {
      setDepartmentMenuOpen(!departmentMenuOpen);
    }
  };

  const handleOptionClick = (type, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [type]: {
        ...(prev[type] || {}),
        [value]: !prev[type]?.[value]
      }
    }));
  };

  const applyFilters = () => {
    const filters = [];
    for (const [type, values] of Object.entries(selectedFilters)) {
      for (const [value, isChecked] of Object.entries(values)) {
        if (isChecked) {
          filters.push({ type, value });
        }
      }
    }
    onSelectFilters(filters);
    setIsOpen(false)
  };

  const resetFilters = () => {
    setSelectedFilters({})
    onSelectFilters([]);
    setIsOpen(false)
  };

  return (
    <div className="relative flex-1 z-20">
      <div className="flex items-center justify-between p-4 bg-gray-800 rounded text-sm md:text-base">
        <span>Filter By</span>
        <FaEllipsisV onClick={toggleMenu} className="cursor-pointer text-xs md:text-base" />
      </div>

      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-48 bg-gray-800 rounded shadow-lg max-w-full sm:w-56">
          <ul className="text-sm text-white">
            {showJobStatusFilter && (
              <>
                <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    checked={!!selectedFilters['Job Status']?.['Raised']}
                    onChange={() => handleOptionClick('Job Status', 'Raised')}
                    className="mr-2"
                  />
                  Raised
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    checked={!!selectedFilters['Job Status']?.['Approved']}
                    onChange={() => handleOptionClick('Job Status', 'Approved')}
                    className="mr-2"
                  />
                  Approved
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    checked={!!selectedFilters['Job Status']?.['Rejected']}
                    onChange={() => handleOptionClick('Job Status', 'Rejected')}
                    className="mr-2"
                  />
                  Rejected
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    checked={!!selectedFilters['Job Status']?.['Completed']}
                    onChange={() => handleOptionClick('Job Status', 'Completed')}
                    className="mr-2"
                  />
                  Completed
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    checked={!!selectedFilters['Job Status']?.['To Be Billed']}
                    onChange={() => handleOptionClick('Job Status', 'To Be Billed')}
                    className="mr-2"
                  />
                  To Be Billed
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    checked={!!selectedFilters['Job Status']?.['Billed But Not Payed']}
                    onChange={() => handleOptionClick('Job Status', 'Billed But Not Payed')}
                    className="mr-2"
                  />
                  Billed But Not Payed
                </li>
                <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                  <input
                    type="checkbox"
                    checked={!!selectedFilters['Job Status']?.['Payment Completed']}
                    onChange={() => handleOptionClick('Job Status', 'Payment Completed')}
                    className="mr-2"
                  />
                  Payment Completed
                </li>
              </>
            )}

            <li
              className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center justify-between"
              onClick={() => toggleNestedMenu('Client')}
            >
              Client
              {clientMenuOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
            </li>
            {clientMenuOpen && (
              <ul className="ml-4 text-sm">
                {clients.map(client => (
                  <li key={client.id} className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                    <input
                      type="checkbox"
                      checked={!!selectedFilters['Client']?.[client.name]}
                      onChange={() => handleOptionClick('Client', client.name)}
                      className="mr-2"
                    />
                    {client.name}
                  </li>
                ))}
              </ul>
            )}

            <li
              className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center justify-between"
              onClick={() => toggleNestedMenu('BTC/CTC')}
            >
              BTC/CTC
              {btcCtcMenuOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
            </li>
            {btcCtcMenuOpen && (
              <ul className="ml-4 text-sm">
                {btcCtc.map(option => (
                  <li key={option.id} className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                    <input
                      type="checkbox"
                      checked={!!selectedFilters['BTC/CTC']?.[option.name]}
                      onChange={() => handleOptionClick('BTC/CTC', option.name)}
                      className="mr-2"
                    />
                    {option.name}
                  </li>
                ))}
              </ul>
            )}

            {showDepartmentFilter && (
              <>
                <li
                  className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center justify-between"
                  onClick={() => toggleNestedMenu('Department')}
                >
                  Department
                  {departmentMenuOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
                </li>
                {departmentMenuOpen && (
                  <ul className="ml-4 text-sm">
                    {department.map(option => (
                      <li key={option.id} className="px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center">
                        <input
                          type="checkbox"
                          checked={!!selectedFilters['Department']?.[option.name]}
                          onChange={() => handleOptionClick('Department', option.name)}
                          className="mr-2"
                        />
                        {option.name === 'Full SBU' ? 'Admin/Head' : option.name}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </ul>
          <button
            onClick={applyFilters}
            className="w-full py-2 bg-blue-500 hover:bg-blue-600 text-white rounded mt-4"
          >
            Apply
          </button>

          <button
            onClick={resetFilters}
            className="w-full py-2 bg-blue-500 hover:bg-blue-600 text-white rounded mt-4"
          >
            Reset
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterMenu;

