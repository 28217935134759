import React from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

const HistoryModal = ({ jobSubject, jobHistory, onClose, loading, error }) => {
  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-lg h-4/5 bg-gray-800 rounded shadow-lg overflow-y-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
    >
      <div className="p-6 relative h-full flex flex-col">
        <button onClick={onClose} className="absolute top-2 right-2 text-white">
          <FaTimes size={20} />
        </button>
        <h2 className="text-xl text-white mb-4">{jobSubject}</h2>

        {loading && !jobHistory && <p className="text-white">Loading...</p>}
        {error && <p className="text-red-400">Error: {error}</p>}
        {jobHistory.length === 0 && !loading && !error && (
          <p className="text-gray-300">No history available for this job.</p>
        )}

        <div className="flex-1 overflow-y-auto mb-6 pr-4">
          {jobHistory.slice().reverse().map((comment, index) => (
            <div key={index} className="p-4 bg-gray-700 rounded mb-4">
           <p className="text-gray-300 mb-2">
                <span className="font-bold">Date:</span> {new Date(comment.commentDatetime).toLocaleDateString()}
            </p>
            <p className="text-gray-300 mb-2">
                 <span className="font-bold">Time:</span> {new Date(comment.commentDatetime).toLocaleTimeString()}
            </p>
              <p className="text-gray-300 mb-2">
                <span className="font-bold">What Changed:</span> {comment.comments}
              </p>
              <p className="text-gray-300">
                <span className="font-bold">Log Statement:</span> {comment.logStatement}
              </p>
              <p className="text-gray-300">
              <span className="font-bold">Attachment:</span> 
              {comment.attachmentUrl && (
                  <a 
                      href={`https://drive.google.com/file/d/${comment.attachmentUrl}/view`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-blue-500 hover:underline ml-2"
                  >
                      View Attachment
                  </a>
              )}
          </p>

            </div>
          ))}
        </div>

        <div className="mb-4"></div> 
      </div>
    </Modal>
  );
};

export default HistoryModal;

