import { useState, useEffect } from 'react';
import { useAuthenticationContext } from '../contexts/AuthenticationContext';
import { useFilterContext } from '../contexts/FilterContext';
import MainMenu from '../components/Menus/MainMenu';
import JobTile from '../components/common/JobTile';
import { useJobUtils } from '../hooks/useJobUtils';
import { EMPLOYEE_LEVELS, JOB_STATUSES } from '../configuration/constants';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'; // React Icons
import { useFetchJobsByStatus } from '../hooks/useFetchJobsByStatus';
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { usePagination } from '../hooks/usePagination'; // Import the pagination hook
import { useAPIContext } from '../contexts/APIContext';

const ApprovedJobs = () => {
  const { employeeAuthenticationData } = useAuthenticationContext();
  const { selectedFilters, setSelectedFilters, filterJobs } = useFilterContext();
  const { checkCondition } = useJobUtils();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { fetchEmployeeDropDownData, employeeDropDownData } = useAPIContext();
  const [selectedEmployeeEmail, setSelectedEmployeeEmail] = useState('');


  const jobsPerPage = 10;

  // Default to 0 for totalJobs until it is fetched
  const [totalJobs, setTotalJobs] = useState(0);

  // Use the usePagination hook, passing the totalJobs after fetching data
  const { currentPage, totalPages, handleNextPage, handlePreviousPage, handlePageClick, getPageNumbers } = usePagination('currentPageApproved', totalJobs, jobsPerPage);

  // Prepare params for API call
  const params = employeeAuthenticationData
    ? {
        jobStatusName: JOB_STATUSES['1'], // Approved job status
        employeeLevel: employeeAuthenticationData.levelData.name,
        teamName: employeeAuthenticationData.teamData.name,
        teamId: employeeAuthenticationData.teamData.id,
        employeeEmail: employeeAuthenticationData.email,
        raisedBySection: 'Approved Jobs',
        approvalStatusId: 2, // Assuming 2 means 'Approved'
        page: currentPage, // Correctly assigning currentPage here
        limit: jobsPerPage, 
      }
    : null;

  // Fetch jobs using the custom hook
  const {
    jobs: approvedJobs,
    loading: fetchJobsLoading,
    error: fetchJobsError,
    totalJobs: fetchedTotalJobs,
  } = useFetchJobsByStatus(params);

  // Update the totalJobs state when fetched
  useEffect(() => {
    if (fetchedTotalJobs !== totalJobs) {
      setTotalJobs(fetchedTotalJobs);
    }
  }, [fetchedTotalJobs, totalJobs]);

  const filteredJobs = filterJobs(approvedJobs, selectedFilters);

  // Cleanup effect to reset filters when navigating away
  useEffect(() => {
    return () => {
      setSelectedFilters([]);
    };
  }, [navigate, setSelectedFilters]);

  useEffect(() => {
    if (employeeAuthenticationData) {
      fetchEmployeeDropDownData(employeeAuthenticationData.teamData.name,employeeAuthenticationData.levelData.name === EMPLOYEE_LEVELS['4']);
    }
  }, [employeeAuthenticationData, fetchEmployeeDropDownData]);
  
  const handleEmployeeChange = (event) => {
    const selectedEmployee = employeeDropDownData.find(
      (employee) => employee.id === event.target.value
    );
    if (selectedEmployee) {
      setSelectedEmployeeEmail(selectedEmployee.email);
    }
  };

  return (
    <div className="p-4 bg-gray-900 text-white min-h-screen">
      <MainMenu
        onSelectFilters={setSelectedFilters}
        showJobStatusFilter={false}
        showDepartmentFilter={checkCondition('showDepartmentFilter')}
        showApproveRejectActions={checkCondition('showApproveRejectActions')}
        //test this
        showReassignOption={employeeAuthenticationData.levelData.name !== EMPLOYEE_LEVELS['1']}
        employeeEmail={selectedEmployeeEmail}
        // showReassignOption={true}
      />

       {/*DropDonw men*/}
       {checkCondition('showApprovedDropDown') && (
        <div className="mt-4">
          <label
            htmlFor="employee-select"
            className="block mb-2 text-sm font-medium text-gray-200"
          >
            Select Employee:
          </label>
          <select
            id="employee-select"
            className="p-2 bg-gray-800 text-white rounded"
            onChange={handleEmployeeChange}
          >
            <option value="">Select an employee</option>
            {employeeDropDownData.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
          </select>
        </div>
      )}

      <div className="mt-4">
        {fetchJobsLoading ? (
          <div className="flex justify-center items-center h-32">
            <ClipLoader color="#ffffff" loading={fetchJobsLoading} size={50} />
          </div>
        ) : fetchJobsError ? (
          <div className="text-center text-red-500">Error: {fetchJobsError}</div>
        ) : (
          <>
            {/* Render job tiles */}
            {filteredJobs.length === 0 ? (
              <div className="text-center text-gray-400">No jobs currently</div>
            ) : (
              filteredJobs.map((job) => (
                <JobTile key={job.id} job={job} pageName={'Approved Jobs'} />
              ))
            )}

            {/* Pagination controls */}
            <div className="flex justify-center mt-4 space-x-2">
              {/* Previous arrow */}
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="text-xl text-gray-300 disabled:text-gray-500"
              >
                <AiOutlineArrowLeft />
              </button>

              {/* Page numbers */}
              {getPageNumbers().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageClick(pageNumber)}
                  className={`px-2 ${currentPage === pageNumber ? 'underline' : 'text-gray-300'}`}
                >
                  {pageNumber}
                </button>
              ))}

              {/* Next arrow */}
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="text-xl text-gray-300 disabled:text-gray-500"
              >
                <AiOutlineArrowRight />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApprovedJobs;






