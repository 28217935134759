import { createContext, useState, useContext } from 'react';
import { useAPIContext } from './APIContext';
import { useAuthenticationContext } from './AuthenticationContext';
import {  JOB_STATUSES } from '../configuration/constants';
import { showAlert } from '../utils/helper';
import { useChangeJobStatus } from '../hooks/useChangeJobStatus'; // Import the custom hook

const ActionContext = createContext();

export const useActionContext = () => useContext(ActionContext);

const ActionProvider = ({ children }) => {
    const { employeeDropDownData, reAssignJobs } = useAPIContext(); // Removed changeJobStatus
    const { employeeAuthenticationData } = useAuthenticationContext();
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ actionName: '', jobCodes: []});

    // Initialize the custom hook
    const changeJobStatus = useChangeJobStatus();

    const toggleJobSelection = (jobCode) => {
        setSelectedJobs((previousSelectedJobs) =>
            previousSelectedJobs.includes(jobCode)
                ? previousSelectedJobs.filter((code) => code !== jobCode)
                : [...previousSelectedJobs, jobCode]
        );
    };

    // employeeEmail only needed in case of to be assigned
    const performAction = (actionName, employeeEmail = '') => {
        if (selectedJobs.length === 0) {
            showAlert('warning', 'No Jobs Selected', 'Please Select At Least One Job');
            return;
        }
        if (actionName === 'Assign') {
            handleJobsAssign(selectedJobs, employeeEmail);
        } else if (actionName === 'Re-Assign') {
            handleReAssignJobs(selectedJobs, employeeEmail);
        } else {
            setModalData({ actionName, jobCodes: selectedJobs });
            setIsModalOpen(true);
        }
    };

    // Being explicit, i.e., singleJobCode to avoid confusion
    const handleSingleJobStatusChange = (actionName, singleJobCode, currentBillingAmount = null) => {
        setModalData({
            actionName: actionName,
            jobCodes: JSON.stringify([singleJobCode]),
            // jobCodes: [singleJobCode],
            currentBillingAmount: currentBillingAmount,
        });
        setIsModalOpen(true);
        console.log('data: ',isModalOpen)
       
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    const handleJobsAssign = (selectedJobs, employeeEmail) => {
        const employee = employeeDropDownData.find(
            (data) => data.email === employeeEmail
        );
    
        const formData = new FormData();
        formData.append('jobCodes', JSON.stringify(selectedJobs));  // Stringify jobCodes array
        formData.append('modifiedByEmail', employeeAuthenticationData.email);
        formData.append('toBeBilledByEmail', employeeEmail);
        formData.append('comments', '');
        formData.append('attachmentUrl', '');
        formData.append('jobStatusName', JOB_STATUSES['3']);
        formData.append('logStatement', `${employeeAuthenticationData.name} assigned billing of this job to ${employee.name}`);

        console.log('Assign Jobs Request Body:', selectedJobs);
    
        changeJobStatus.mutate(formData, {
            onSuccess: () => {
                setSelectedJobs([]);
                showAlert('success', 'Success', 'Jobs assigned successfully');
            },
            onError: (error) => {
                showAlert('error', 'Error', error.message || 'An error occurred');
            },
        });
    };
    
    const handleReAssignJobs = (selectedJobs, employeeEmail) => {
        console.log('Re-assigned to:', employeeEmail);
        console.log('Jobs:', selectedJobs);
    
        const employee = employeeDropDownData.find(
            (data) => data.email === employeeEmail
        );
    
        const requestBody = {
          jobCodes: selectedJobs,
          reassignerEmail: employeeAuthenticationData.email,
          assignedToEmail: employeeEmail,
          logStatement: `${employeeAuthenticationData.name} re-assigned job to ${employee.name}`,
        };
        console.log('Reassign Jobs Request Body:', requestBody);
        reAssignJobs(requestBody);
        setSelectedJobs([]);
      };
    


    return (
        <ActionContext.Provider
            value={{
                selectedJobs,
                setSelectedJobs,
                handleSingleJobStatusChange,
                toggleJobSelection,
                performAction,
                isModalOpen,
                modalData,
                closeModal,
            }}
        >
            {children}
        </ActionContext.Provider>
    );
};

export default ActionProvider;
