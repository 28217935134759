import { createContext, useContext, useState, useMemo, useCallback } from "react";
import { commonGetRequest, commonPostRequest } from '../api/CommonRequests';
import { showAlert } from "../utils/helper";
import { useMutation, useQueryClient } from '@tanstack/react-query'



const APIContext = createContext();

export const useAPIContext = () => useContext(APIContext);

//form drop down data is used to provide the drop down options in the JobForm
//data for the options is fetched directly from the backend
//clients, to be executed by,frequency types,billing types,sbus,btc ctc are all fields needed in the JobForm
const initialFormDropDownData = {
    clients: [],
    toBeExecutedBy: [],
    frequencyTypes: [],
    billingTypes: [],
    sbus: [],
    btcCtc: []
};


const APIProvider = ({ children }) => {
    //set initial form drop down data to an object with arrays which will be dynamically populated upon API call
    const [formDropDownData, setFormDropDownData] = useState(initialFormDropDownData);

    //this is used to fetch the id,name and email of various employees
    //it is used in ApprovedJobs page so that a job which has been Approved can be re-assigned to someone else 
    //it is used in ToBeAssignedJobs page so that a finance employee can assign billing
    //it is used in ToBeBilledJobs page so that a finance employee can re-assign the billling 
    //it is used in PaymentPending page so that a finance employee can re-assign as to who can mark the payment as complete
    const [employeeDropDownData,setEmployeeDropDownData] = useState([]);

    //passed from JobTile to history modal to see the history of the changes made to it such as change in job phase,re-assign etc
    //stores all the comments from a particular job (this comes from comments table in the backend, not the jobs table)
    const [jobHistory,setJobsHistory] = useState([]);

    //general loading and error states for the application
    //spinner is shown in case if loading is true
    //used in most places in the application where data is fetched 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    //stores jobs based on the job code,used in SearchJobs page
    const [jobsByCode,setJobsByCode] = useState([])

    //used in the MainPage to show the count for jobs in each page under respective tabs ie General and Finance
    //stores the job count i.e number of jobs for each page ie:
            //General -> Jobs Raised,Jobs Approved,Jobs Raised
            //Finance -> To Be Assigned,To Be Billed,Payment Pending,Payment Completed
    const [jobsCountData,setJobsCountData] = useState([])

    //create instance of 
    const queryClient = useQueryClient();

    //Note:
        //commonPostRequest and commonGetRequest are defined in CommonRequest.js 
        //and they return an object:
            //in case of successfull response {sucess: true,data: response data}
            //in case of unsucessfull response {sucess: false,errorMessage: error text}
    
    //used in to fetch the drop down data in JobForm ie formDropDownData 
    const fetchFormDropDownData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const responseBody = await commonGetRequest('FORM_DROP_DOWN_DATA');
            if (responseBody.success) {
                setFormDropDownData(responseBody.data.dropDownData); 
            } else {
                setError(responseBody.errorMessage);
            }
        } catch (error) {
            setError(error.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    }, []);



    //this is used to fetch the employees drop down data in ApprovedJobs,ToBeAssignedJobs,ToBeBilledJobs,PaymentPending
    // ie employeeDropDownData
    const fetchEmployeeDropDownData = useCallback(async (employeeTeam,isAdmin=false) => {
        setLoading(true);
        setError(null);
        try {
            const responseBody = await commonPostRequest({employeeTeam: employeeTeam,isAdmin: isAdmin},'EMPLOYEE_DROP_DOWN_DATA');
            if (responseBody.success) {
                console.log('employee drop down data: ',responseBody.data.dropDownData)
                setEmployeeDropDownData(responseBody.data.dropDownData); 
            } else {
                setError(responseBody.errorMessage);
            }
        } catch (error) {
            setError(error.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    }, []);
    

    //this is for submitting the JobForm
    const submitJobForm = useCallback(async (formData) => {
        setLoading(true);
        setError(null);
        
        // Directly log the FormData entries to ensure it's working
        console.log("FormData to be submitted in submitJobForm:");
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
    
        try {
            const responseBody = await commonPostRequest(formData, 'SUBMIT_JOB_FORM',true,true);
            if (responseBody.success) {
                //alert to show successfull form submission (in helper.js)
                showAlert('success', 'Success', `Job with code ${responseBody.data.jobCode} raised`);
            } else {
                showAlert('error', 'Error', responseBody.errorMessage || 'An error occurred');
                setError(responseBody.errorMessage);
            }
        } catch (error) {
            //alert to show error in case the request failed
            showAlert('error', 'Network Error', error.message || 'A network error occurred');
            setError(error.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    }, []);
    
    
    

    //for getting the count of the jobs on the MainPage,based the category under which they fall
    //so that count can be displayed in the blocks on the MainPage, before the users clicks on one of the blocks and navigates to a page
    //this is done so that the jobs count can be fetched without fetching all the jobs (which would make it more time consumimg)
    const getJobsCountData = useCallback(async (requestBody) => {
        setLoading(true);
        setError(null);
        try {
            const responseBody = await commonPostRequest(requestBody, 'JOBS_COUNT');
            if (responseBody.success) {
                console.log('response job count: ',responseBody.data.jobsCount)
                setJobsCountData(responseBody.data.jobsCount)
            } else {
                setError(responseBody.errorMessage);
            }
        } catch (error) {
            setError(error.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    }, []);
    



    //this is for fetching the job history (i.e comments)
    const fetchJobHistory = useCallback(async (requestBody) => {
        setLoading(true);
        setError(null);
        try {
            const responseBody = await commonPostRequest(requestBody, 'JOB_HISTORY');
            if (responseBody.success) {
                setJobsHistory(responseBody.data.comments)
            } else {
                setError(responseBody.errorMessage);
            }
        } catch (error) {
            setError(error.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    }, []);



    //useMutation is part of react query and is used  ans simiplifies data mutations
    //can be helpful for handling side effects,ui updates such as re-fetching jobs once a job is re-assigned to reflect the relevant changes in job details
    const reAssignJobsMutation = useMutation({
        //mutationFn is the function tha actually performs the mutation
        mutationFn: async (requestBody) => {
        const responseBody = await commonPostRequest(requestBody, 'RE_ASSIGN_JOBS');
        if (responseBody.success) {
            return responseBody;
        } else {
            throw new Error(responseBody.errorMessage || 'An error occurred');
        }
        },
        //onMutate is called right before the mutation function is executed
        onMutate: () => {
            setLoading(true);
            setError(null);
        },
        //onSucess is called when the mutation is sucessfull
        onSuccess: (data) => {
            showAlert('success', 'Success', data.data.message);
            //this invalidates any queries that match the predicate condition
            //in this case it is used to run 'fetchJobsByStatus' again(see useFetchJobsByStatus.js where this query key is set)
            //this refetches the jobs
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'fetchJobsByStatus',
            });
        },
        //onError is called if the mutation fails
        onError: (error) => {
            showAlert('error', 'Error', error.message || 'An error occurred');
            setError(error.message || 'An error occurred');
        },
        //onSettled is called if mutation either succeeds or fails
        onSettled: () => {
            setLoading(false);
        },
    });

    // Wrap the mutation in a callback for use in components
    const reAssignJobs = useCallback(
        (requestBody) => {
        reAssignJobsMutation.mutate(requestBody);
        },
        [reAssignJobsMutation]
    );

    //search jobs by code
    //right now in search jobs anyone can search one or multiple job based on job code and can look at the details
    //however no action can be taken by anyone such as approve the job,re-assign it etc 
  
    const fetchJobsByCode = useCallback(async (jobCodes) => {
        setLoading(true);
        setError(null);
    
        console.log('job codes data: ', jobCodes);
    
        try {
            const responseBody = await commonPostRequest({ jobCodes: jobCodes }, 'SEARCH_JOBS');
            if (responseBody.success) {
                setJobsByCode(responseBody.data.jobs);
    
                const missingJobCodes = responseBody.data.missing_job_codes;
                if (missingJobCodes.length > 0) {
                    setError(`Jobs with codes ${missingJobCodes.join(', ')} do not exist.`);
                }
            } else {
                setError(responseBody.errorMessage);
            }
        } catch (error) {
            setError(error.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    }, []);
    

    
    //Note:
        //useCallback esnures that functions are stable and not re-rendered unecessarily
        //especially when functions are passed as props to child components.

        //useMemo ensures that the context value object is stable
        //use it to for memoizing expensive calculations or derived data

        //useCallback eg:
        // const incrementCounter = useCallback(() => {
        //     setCounter(prevCounter => prevCounter + 1);
        //   }, []);
        //function not re-rendered when passed as prop


        //useMemo eg:
        // const filteredItems = useMemo(() => {
        //     console.log('Filtering items...');
        //     return items.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
        //   }, [items, searchTerm]);
        // The filter operation only runs when items or searchTerm changes
    const value = useMemo(() => ({
        formDropDownData,
        employeeDropDownData,
        fetchFormDropDownData,
        fetchEmployeeDropDownData,
        submitJobForm, 
        reAssignJobs,
        fetchJobHistory,
        getJobsCountData,
        jobsCountData,
        jobsByCode,
        fetchJobsByCode,
        loading,
        error, 
        jobHistory,
    }), [formDropDownData
        ,jobsCountData,
        loading,
        error,
        jobsByCode,
        setJobsByCode,
        fetchFormDropDownData,
        fetchEmployeeDropDownData,
        reAssignJobs,
        submitJobForm,
        jobHistory,
        fetchJobHistory]);

    return (
        <APIContext.Provider value={value}>
            {children}
        </APIContext.Provider>
    );
};

export default APIProvider;
