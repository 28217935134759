const CustomInput = ({ labelName, type, fieldName, setFieldName }) => {
    return (
        <>
         { type === "textarea" ? 
             <div className="mb-4">
                <label className="block text-white">{labelName}</label>
                <textarea
                    className="w-full bg-gray-700 border border-gray-600 rounded px-3 py-2 text-white h-28"
                    value={fieldName}
                    onChange={(event) => setFieldName(event.target.value)}
                />
             </div> : 
             <div className="mb-4">
                <label className="block text-white">{labelName}</label>
                <input
                    type={type}
                    className={`w-full bg-gray-700 border border-gray-600 rounded px-3 py-2 text-white`}
                    value={fieldName}
                    onChange={(event) => setFieldName(event.target.value)}
                    required
                />

             </div>

         }

        </>
    );
}

export default CustomInput;
