import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticationContext } from '../contexts/AuthenticationContext'
import { commonPostRequest } from "../api/CommonRequests"

function EmployeeLogin() {
  //employeeLogin function coming from AuthenticationContext
  const { employeeLogin } = useAuthenticationContext();
  
  //form data
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  //error message in case request fails
  const [errorMessage, setErrorMessage] = useState();

  //navigator hook to navigate to main page in case of successfull login
  const navigator = useNavigate();

  //handle submition of form
  async function handleLoginFormSubmit(event){
    event.preventDefault();
    const requestBody = {email: email.toLowerCase(),password: password}
    try {
      const responseBody = await commonPostRequest(requestBody,'EMPLOYEE_LOGIN');
      if (responseBody.success) {
        //use employeeLogin to set the local storage to the returned reponseBody containing employee data 
        employeeLogin(responseBody.data);
        //navigate to the MainPage
        navigator('/job_bag/main_page');
      } else {
        //set error in case of unsuccesfull request
        setErrorMessage(responseBody.errorMessage);
      }
    } catch (error) {
      //set error in case of unsuccesfull request
      setErrorMessage(error.message || error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-900 text-white p-4">
      <form
        onSubmit={handleLoginFormSubmit}
        className="bg-gray-800 p-6 rounded-md w-full max-w-md mx-4 shadow-lg"
      >
        <h2 className="text-2xl font-bold mb-6 text-neon-blue">Login</h2>

        {/*Email input*/}
        <div className="mb-4">
          <label className="block text-neon-blue mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-neon-blue focus:outline-none focus:ring-2 focus:ring-neon-blue"
          />
        </div>

        {/*Password input */}
        <div className="mb-6">
          <label className="block text-neon-blue mb-2" htmlFor="password">
            Password
          </label>
          <input
            type="text"
            id="password"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-neon-blue focus:outline-none focus:ring-2 focus:ring-neon-blue"
          />
        </div>

        {/*error message to be displayed in case employee login fails */}
        {errorMessage && (
          <div className="mb-4 text-red-400">
            {errorMessage}
          </div>
        )}

        {/*Login button */}
        <button
          type="submit"
          className="w-full bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded transition duration-200 transform hover:scale-105 hover:shadow-lg"
        >
          Login
        </button>

      </form>
    </div>
  );
}

export default EmployeeLogin;
