import { useFilterContext } from '../contexts/FilterContext';
import MainMenu from '../components/Menus/MainMenu';
import { useAuthenticationContext } from '../contexts/AuthenticationContext';
import { useEffect, useState } from 'react';
import MyJobsSection from '../components/JobSections/MyJobsSection';
import DepartmentJobsSection from '../components/JobSections/DepartmentJobsSection';
import AllJobsSection from '../components/JobSections/AllJobsSection';
import { useJobUtils } from '../hooks/useJobUtils';
import { useNavigate } from 'react-router-dom'; 

const RaisedJobs = () => {
  const { employeeAuthenticationData } = useAuthenticationContext();
  const {setSelectedFilters} = useFilterContext();
  const { checkCondition } = useJobUtils();
  const navigate = useNavigate();

  const [isMyJobsOpen, setIsMyJobsOpen] = useState(true);
  const [isDepartmentJobsOpen, setIsDepartmentJobsOpen] = useState(false);
  const [isAllJobsOpen, setIsAllJobsOpen] = useState(true);

  const toggleMyJobs = () => setIsMyJobsOpen(!isMyJobsOpen);
  const toggleDepartmentJobs = () => setIsDepartmentJobsOpen(!isDepartmentJobsOpen);
  const toggleAllJobs = () => setIsAllJobsOpen(!isAllJobsOpen);


  // Cleanup effect to reset filters when navigating away
  useEffect(() => {
    return () => {
      setSelectedFilters([]); 
    };
  }, [navigate, setSelectedFilters]);

  return (
    <div className="p-4 bg-gray-900 text-white min-h-screen">
      <MainMenu
        onSelectFilters={setSelectedFilters}
        showJobStatusFilter={false}
        showDepartmentFilter={checkCondition('showDepartmentFilter')}
        showApproveRejectActions={checkCondition('showApproveRejectActions')}
      />
    
      {checkCondition('showMyJobsSection') && (
        <MyJobsSection
          key="myJobs"
          isMyJobsOpen={isMyJobsOpen}
          toggleMyJobs={toggleMyJobs}
          employeeAuthenticationData={employeeAuthenticationData}
        />
      )}

      {checkCondition('showDepartmentSection') && (
        <DepartmentJobsSection
          key="departmentJobs"
          isDepartmentJobsOpen={isDepartmentJobsOpen}
          toggleDepartmentJobs={toggleDepartmentJobs}
          employeeAuthenticationData={employeeAuthenticationData}
        />
      )}

      {checkCondition('showAllJobsSection') && (
        <AllJobsSection
          isAllJobsOpen={isAllJobsOpen}
          toggleAllJobs={toggleAllJobs}
          employeeAuthenticationData={employeeAuthenticationData}
        />
      )}
    </div>
  );
};

export default RaisedJobs;



