// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthenticationProvider from './contexts/AuthenticationContext';
import APIProvider from './contexts/APIContext';
import FilterProvider from './contexts/FilterContext';
import ActionProvider from './contexts/ActionContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthenticationProvider>
        <APIProvider>
          <FilterProvider>
            <ActionProvider>
              <App />
            </ActionProvider>
          </FilterProvider>
        </APIProvider>
      </AuthenticationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();

