import { useState, useEffect } from 'react';
import { useAuthenticationContext } from '../contexts/AuthenticationContext';
import { useFilterContext } from '../contexts/FilterContext';
import MainMenu from '../components/Menus/MainMenu';
import JobTile from '../components/common/JobTile';
import { JOB_STATUSES } from '../configuration/constants';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useFetchJobsByStatus } from '../hooks/useFetchJobsByStatus';
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from 'react-router-dom';
import { usePagination } from '../hooks/usePagination'; // Import the pagination hook

const PaymentCompleted = () => {
  const { employeeAuthenticationData } = useAuthenticationContext();
  const { selectedFilters, setSelectedFilters, filterJobs } = useFilterContext();

  const navigate = useNavigate(); // Initialize useNavigate

  

  const jobsPerPage = 10;

  // Default to 0 for totalJobs until it is fetched
  const [totalJobs, setTotalJobs] = useState(0);

  // Use the usePagination hook, passing the totalJobs after fetching data
  const { currentPage, totalPages, handleNextPage, handlePreviousPage, handlePageClick, getPageNumbers } = usePagination('currentPagePaymentComplete', totalJobs, jobsPerPage);

  // Prepare params for the API request
  const params = employeeAuthenticationData
    ? {
        jobStatusName: JOB_STATUSES['5'], // Payment completed job status
        employeeLevel: employeeAuthenticationData.levelData.name,
        teamName: employeeAuthenticationData.teamData.name,
        teamId: employeeAuthenticationData.teamData.id,
        employeeEmail: employeeAuthenticationData.email,
        page: currentPage, // Correctly assigning currentPage here
        limit: jobsPerPage,
      }
    : null;

  // Fetch jobs using the custom hook
  const {
    jobs: paymentCompleteJobs,
    loading: fetchJobsLoading,
    error: fetchJobsError,
    totalJobs: fetchedTotalJobs,
  } = useFetchJobsByStatus(params);

  // Update the totalJobs state when fetched
  useEffect(() => {
    if (fetchedTotalJobs !== totalJobs) {
      setTotalJobs(fetchedTotalJobs);
    }
  }, [fetchedTotalJobs, totalJobs]);

  const filteredJobs = filterJobs(paymentCompleteJobs, selectedFilters);

  // Cleanup effect to reset filters when navigating away
  useEffect(() => {
    return () => {
      setSelectedFilters([]); 
    };
  }, [navigate, setSelectedFilters]);





  return (
    <div className="p-4 bg-gray-900 text-white min-h-screen">
      <MainMenu
        onSelectFilters={setSelectedFilters}
        showJobStatusFilter={false}
        showDepartmentFilter={true}
        showApproveRejectActions={false}
       
      />

     
     

      <div className="mt-4">
        {fetchJobsLoading ? (
          <div className="flex justify-center items-center h-32">
            <ClipLoader color="#ffffff" loading={fetchJobsLoading} size={50} />
          </div>
        ) : fetchJobsError ? (
          <div className="text-center text-red-500">Error: {fetchJobsError}</div>
        ) : (
          <>
            {/* Render job tiles */}
            <div className="mt-4">
              {filteredJobs.map((job) => (
                <JobTile key={job.id} job={job} />
              ))}
            </div>

            {/* Pagination controls */}
            <div className="flex justify-center mt-4 space-x-2">
              {/* Previous arrow */}
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="text-xl text-gray-300 disabled:text-gray-500"
              >
                <AiOutlineArrowLeft />
              </button>

              {/* Page numbers */}
              {getPageNumbers().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageClick(pageNumber)}
                  className={`px-2 ${currentPage === pageNumber ? 'underline' : 'text-gray-300'}`}
                >
                  {pageNumber}
                </button>
              ))}

              {/* Next arrow */}
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="text-xl text-gray-300 disabled:text-gray-500"
              >
                <AiOutlineArrowRight />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentCompleted;

