import { Link } from 'react-router-dom';

//first page that appears when the application starts
function EntryPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
      <h1 className="text-5xl font-extrabold mb-10 text-neon-blue shadow-neon">
        Job Bag
      </h1>

      <div className="space-y-4">
        <Link to="/employee_login">
        <button className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-6 rounded-md transition duration-200 ease-in-out transform hover:scale-105 hover:shadow-lg">
            Login
        </button>
        </Link>
      </div>

      <div className="mt-12 w-3/4 border-t border-gray-700"></div>
      <p className="mt-4 text-sm text-gray-500">
        © 2024 ND Commerce. All rights reserved.
      </p>
    </div>
  );
}

export default EntryPage;