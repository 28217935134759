import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useAPIContext } from "../contexts/APIContext";
import JobTile from "../components/common/JobTile";
import ClipLoader from 'react-spinners/ClipLoader';

const SearchJobs = () => {
  const [jobCodesQuery, setJobCodesQuery] = useState("");
  const { jobsByCode, fetchJobsByCode, loading, error } = useAPIContext();

  const handleSearch = () => {
    fetchJobsByCode(jobCodesQuery);
  };

  return (
    <div className="p-4 bg-gray-900 text-white min-h-screen flex justify-center items-start">
      {/* Main Container */}
      <div className="flex flex-col w-[70%] sm:w-[80%] max-w-4xl">
        {/* Input Field with Search Button */}
        <div className="relative mt-10">
          <input
            type="text"
            value={jobCodesQuery}
            onChange={(e) => setJobCodesQuery(e.target.value)}
            placeholder="Search for jobs..."
            className="w-full p-3 pr-12 rounded-full bg-gray-800 text-white outline-none focus:ring-2 focus:ring-gray-500"
          />
          <button
            onClick={handleSearch}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-gray-700 p-2 rounded-full text-gray-400 hover:text-white focus:outline-none"
          >
            <FaSearch size={18} />
          </button>
        </div>

        {/* Results Section */}
        <div className="mt-8">
  {loading ? (
    <div className="flex justify-center items-center h-32">
      <ClipLoader color="#ffffff" loading={loading} size={50} />
    </div>
  ) : (
    <>
      {error && <div className="text-center text-red-500">{error}</div>}
      {/* Render job tiles */}
      {jobsByCode.length === 0 && !error ? (
        <div className="text-center text-gray-400">No jobs currently</div>
      ) : (
        jobsByCode.map((job) => (
          <JobTile key={job.id} job={job} allCheckBoxesDisabled={true} />
        ))
      )}
    </>
  )}
</div>

      
      </div>
    </div>
  );
};

export default SearchJobs;






// import { useState } from "react";
// import { FaSearch } from "react-icons/fa";
// import { useAPIContext } from "../contexts/APIContext";
// import JobTile from "../components/common/JobTile";
// import { ClipLoader } from "react-spinners";

// const SearchJobs = () => {
//   const [jobCodesQuery, setJobCodesQuery] = useState("");
//   const { jobsByCode, fetchJobsByCode, loading, error } = useAPIContext();

//   const handleSearch = () => {
//     fetchJobsByCode(jobCodesQuery);
//   };

//   return (
//     <div className="p-4 bg-gray-900 text-white min-h-screen">
//       <div className="flex justify-center">
//         <div className="relative w-full max-w-2xl">
//           <input
//             type="text"
//             value={jobCodesQuery}
//             onChange={(e) => setJobCodesQuery(e.target.value)}
//             className="w-full p-4 pr-12 bg-gray-800 text-white rounded-full outline-none focus:ring-2 focus:ring-gray-600"
//             placeholder="Enter job code or keywords..."
//           />
//           <button
//             onClick={handleSearch}
//             className="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 hover:text-white"
//             style={{
//               height: '24px',
//               width: '24px',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center'
//             }}
//           >
//             <FaSearch size={16} />
//           </button>

//           <div className="mt-8">
//             {loading ? (
//               <div className="flex justify-center items-center h-32">
//                 <ClipLoader color="#ffffff" loading={loading} size={50} />
//               </div>
//             ) : error ? (
//               <div className="text-center text-red-500">Error: {error}</div>
//             ) : (
//               <>
//                 {/* Render job tiles */}
//                 {jobsByCode.length === 0 ? (
//                   <div className="text-center text-gray-400">No jobs currently</div>
//                 ) : (
//                   jobsByCode.map((job) => (
//                     <JobTile key={job.id} job={job} allCheckBoxesDisabled={true} />
//                   ))
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SearchJobs;







// import { useState } from "react";
// import { FaSearch } from "react-icons/fa";
// import { useAPIContext } from "../contexts/APIContext";
// import JobTile from "../components/common/JobTile";
// import { ClipLoader } from "react-spinners";

// const SearchJobs = () => {
//   const [jobCodesQuery, setJobCodesQuery] = useState("");
//   const { jobsByCode, fetchJobsByCode, loading, error } = useAPIContext();

//   const handleSearch = () => {
//     fetchJobsByCode(jobCodesQuery);
//   };

//   return (
//     <div className="p-4 bg-gray-900 text-white min-h-screen">
//       <div className="flex justify-center">
//         <div className="relative w-full max-w-2xl">

//           </div>
//         </div>
//       </div>
   
//   );
// };

// export default SearchJobs;


