import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { useActionContext } from "../../contexts/ActionContext";
import { JOB_STATUSES,APPROVAL_STATUSES } from "../../configuration/constants";
import { useJobUtils } from "../../hooks/useJobUtils";

const DetailsModal = ({ job, onClose, allowApprovalStatusChange }) => {
  const { handleSingleJobStatusChange } = useActionContext();
  const {checkCondition} = useJobUtils()
  const nullValueText = "Not defined yet";

  // const [billingModalOpen, setBillingModalOpen] = useState(false);

  const renderDetail = (label, value) => (
    <div className="text-gray-300 grid grid-cols-2 gap-x-1 mb-1 ml-4">
      <span className="font-bold">{label}:</span>
      <span>{value || nullValueText}</span>
    </div>
  );
  
  

  const canMarkAsComplete = checkCondition('canMarkAsComplete',job,allowApprovalStatusChange)
  const canBillJob = checkCondition('canBillJob',job)
  const canCompletePayment = checkCondition('canCompletePayment',job)

  const handleActionSelect = (action, jobCode,billingAmount=null) => {
    console.log('acti: ',action,jobCode)
    handleSingleJobStatusChange(action, jobCode,billingAmount);
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        className="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-3xl h-4/5 bg-gray-800 rounded shadow-lg overflow-y-auto z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="flex-1">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-white bg-gray-700 p-2 rounded-full hover:bg-gray-600 focus:outline-none"
          >
            <FaTimes size={20} />
          </button>
          <h2 className="text-xl text-white mb-4 ml-4 mt-4">
            {job.jobSubject || nullValueText}
          </h2>
          {renderDetail("Job Code", job.jobCode)}
          {renderDetail("Client", job.client?.name)}
          {renderDetail("Raised By", job.raisedByName)}
          {renderDetail("Raised By Team", job.raisedByTeam?.name)}
          {renderDetail("Raised By SBU", job.raisedBySbu?.name)}
          {renderDetail("Billing Type", job.billingType?.name)}
          {renderDetail("Job Frequency", job.jobFrequency?.name)}
          {renderDetail("Closure Expected By", job.closureExpectedBy)}
          {renderDetail("To Be Executed By", job.toBeExecutedBy?.name)}
          {renderDetail("Billing Amount", job.billingAmount)}
          {renderDetail("Job Raised Date Time", job.jobRaisedDateTime)}
          {renderDetail("Job Phase", job.jobPhase?.name)}
          {renderDetail("Approval Status", job.approvalStatus?.name)}
          {renderDetail("Approver Email", job.approver?.email)}
          {renderDetail("Approver Name", job.approver?.name)}
          {renderDetail("Approver Level ID", job.approver?.levelId)}
          {renderDetail("BTC/CTC", job.btcCtc)}
          {renderDetail("To Be Billed By", job.toBeBilledBy)}
          {renderDetail("Billing Assigned By", job.billingAssignedBy)}
          {renderDetail("Tax", job.tax)}
          {renderDetail("Total Amount", job.totalAmount)}
          {renderDetail("Invoice", job.invoice)}
          {renderDetail("Payment To Be Completed By",job.paymentToBeCompletedBy)}

          {/* Action Buttons */}
          {allowApprovalStatusChange && (
            <div className="flex justify-end mt-4">
              <button
                onClick={() => handleActionSelect(APPROVAL_STATUSES['2'], job.jobCode)}
                className="mb-4 bg-green-500 text-white px-4 py-2 rounded mr-2 hover:bg-green-400 focus:outline-none ml-4"
              >
                Approve
              </button>
              <button
                onClick={() => handleActionSelect(APPROVAL_STATUSES['3'], job.jobCode)}
                className="mb-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-400 focus:outline-none ml-4"
              >
                Reject
              </button>
              
            </div>
          )}
          {canMarkAsComplete && (
            <button
              onClick={() =>handleActionSelect(JOB_STATUSES['2'], job.jobCode)}
              className="mb-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400 focus:outline-none ml-4"
            >
              Mark as Complete
            </button>
          )}
          {canBillJob && (
            <button
              onClick={() => handleActionSelect(JOB_STATUSES['4'], job.jobCode,job.billingAmount)}
              className="mb-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400 focus:outline-none ml-4"
            >
              Bill Job
            </button>
          )}
             {canCompletePayment && (
            <button
              onClick={() => handleActionSelect(JOB_STATUSES['5'], job.jobCode)}
              className="mb-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400 focus:outline-none ml-4"
            >
              Payment Complete 
            </button>
          )}
        </div>
     
      </Modal>

    </>
  );
};

export default DetailsModal;