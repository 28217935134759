import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaBars, FaUser, FaHome } from 'react-icons/fa';
import { useAuthenticationContext } from '../../contexts/AuthenticationContext';

const Navbar = () => {
  const { employeeLogout, employeeAuthenticationData } = useAuthenticationContext();

  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGeneralOpen, setIsGeneralOpen] = useState(false);
  const [isFinanceOpen, setIsFinanceOpen] = useState(false);

  const currentPath = location.pathname;

  const getTitle = () => {
    switch (currentPath) {
      case '/job_bag/job_form':
        return 'Job Form';
      case '/job_bag/raised_jobs':
        return 'Jobs Raised';
      case '/job_bag/approved_jobs':
        return 'Jobs Approved';
      case '/job_bag/rejected_jobs':
        return 'Jobs Rejected';
      case '/job_bag/all_jobs':
        return 'All Jobs';
      case '/job_bag/to_be_assigned_jobs':
        return 'Jobs To Be Assigned';
      case '/job_bag/to_be_billed_jobs':
        return 'Jobs To Be Billed';
      case '/job_bag/payment_completed':
        return 'Payment Completed';
      case '/job_bag/payment_pending':
        return 'Payment Pending';
      case '/job_bag/search_jobs':
         return 'Search Jobs'
      default:
        return 'Job Bag'; 
    }
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleGeneral = () => setIsGeneralOpen(!isGeneralOpen);
  const toggleFinance = () => setIsFinanceOpen(!isFinanceOpen);

  const handleLogout = () => {
    employeeLogout();
    navigate('/');
  };

  return (
    <nav className="bg-black text-white p-4 flex items-center justify-between sticky top-0 z-50">
      {/* Sandwich Icon */}
      <button onClick={toggleMenu} className="flex items-center">
        <FaBars size={24} />
      </button>

      {/*Main Page */}
      <div className="flex items-center space-x-4 ml-4">
        <Link to="/job_bag/main_page">
          <FaHome size={24} />
        </Link>
    </div>

      {/* Title */}
      <div className="text-center flex-1">
        <h1 className="text-2xl font-bold">{getTitle()}</h1>
      </div>

      {/* User Icon */}
      <div className="flex items-center space-x-4">
        <FaUser size={24} />
        <span className="hidden md:inline">{employeeAuthenticationData.name}</span>
        <button className="bg-gray-800 p-2 rounded hover:bg-gray-700 text-sm" onClick={handleLogout}>Logout</button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-75 z-50 transition-transform transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:hidden`}
      >
        <div className="p-4">
          <button onClick={toggleMenu} className="text-white mb-4">
            <FaChevronUp size={24} />
          </button>
          <ul className="mt-4 space-y-2">
            <li>
              <button
                onClick={toggleGeneral}
                className="flex items-center w-full p-2 bg-gray-800 rounded hover:bg-gray-700"
              >
                <span className="flex-1">General</span>
                {isGeneralOpen ? <FaChevronUp /> : <FaChevronDown />}
              </button>
              {isGeneralOpen && (
                <ul className="mt-2 space-y-2 pl-4">
                  <li>
                    <Link to="/job_bag/job_form" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Job Form
                    </Link>
                  </li>
                  <li>
                    <Link to="/job_bag/raised_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Jobs Raised
                    </Link>
                  </li>
                  <li>
                    <Link to="/job_bag/approved_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Jobs Approved
                    </Link>
                  </li>
                  <li>
                    <Link to="/job_bag/rejected_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Jobs Rejected
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                onClick={toggleFinance}
                className="flex items-center w-full p-2 bg-gray-800 rounded hover:bg-gray-700"
              >
                <span className="flex-1">Finance</span>
                {isFinanceOpen ? <FaChevronUp /> : <FaChevronDown />}
              </button>
              {isFinanceOpen && (
                <ul className="mt-2 space-y-2 pl-4">
                  <li>
                    <Link to="/job_bag/to_be_assigned_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Jobs To Be Assigned
                    </Link>
                  </li>
                  <li>
                    <Link to="/job_bag/to_be_billed_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Jobs To Be Billed
                    </Link>
                  </li>
                  <li>
                    <Link to="/job_bag/payment_pending" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Payment Pending
                    </Link>
                  </li>
                  <li>
                    <Link to="/job_bag/payment_completed" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                      Payment Completed
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/job_bag/all_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                All Jobs
              </Link>
              <Link to="/job_bag/search_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600 mt-2" onClick={() => setIsMenuOpen(false)}>
               Search Jobs
            </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Desktop Menu */}
      <div className={`hidden md:flex md:flex-col md:w-64 md:fixed md:left-0 md:top-0 md:bg-gray-900 md:h-full md:space-y-4 md:p-4 md:transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="mb-4">
          <button onClick={toggleMenu} className="text-white">
            <FaChevronUp size={24} />
          </button>
        </div>
        <ul className="space-y-2">
          <li>
            <button
              onClick={toggleGeneral}
              className="flex items-center w-full p-2 bg-gray-800 rounded hover:bg-gray-700"
            >
              <span className="flex-1">General</span>
              {isGeneralOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {isGeneralOpen && (
              <ul className="mt-2 space-y-2 pl-4">
                <li>
                  <Link to="/job_bag/job_form" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Job Form
                  </Link>
                </li>
                <li>
                  <Link to="/job_bag/raised_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Jobs Raised
                  </Link>
                </li>
                <li>
                  <Link to="/job_bag/approved_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Jobs Approved
                  </Link>
                </li>
                <li>
                  <Link to="/job_bag/rejected_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Jobs Rejected
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <button
              onClick={toggleFinance}
              className="flex items-center w-full p-2 bg-gray-800 rounded hover:bg-gray-700"
            >
              <span className="flex-1">Finance</span>
              {isFinanceOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {isFinanceOpen && (
              <ul className="mt-2 space-y-2 pl-4">
                <li>
                  <Link to="/job_bag/to_be_assigned_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Jobs To Be Assigned
                  </Link>
                </li>
                <li>
                  <Link to="/job_bag/to_be_billed_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Jobs To Be Billed
                  </Link>
                </li>
                <li>
                  <Link to="/job_bag/payment_pending" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Payment Pending
                  </Link>
                </li>
                <li>
                  <Link to="/job_bag/payment_completed" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
                    Payment Completed
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/job_bag/all_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600" onClick={() => setIsMenuOpen(false)}>
              All Jobs
            </Link>

            <Link to="/job_bag/search_jobs" className="block p-2 bg-gray-700 rounded hover:bg-gray-600 mt-2" onClick={() => setIsMenuOpen(false)}>
               Search Jobs
            </Link>
            
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;





