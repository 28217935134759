import { useEffect, useState } from 'react';
import { useAPIContext } from '../../contexts/APIContext';
import FilterMenu from './FilterMenu';
import SortMenu from './SortMenu';
import ActionMenu from './ActionMenu';

const MainMenu = ({onSelectFilters,showJobStatusFilter,showDepartmentFilter,showApproveRejectActions,employeeEmail=null,page=null,showReassignOption=false}) => {
  const { fetchFormDropDownData, formDropDownData } = useAPIContext();

  useEffect(() => {
    fetchFormDropDownData();
  }, [fetchFormDropDownData]);


  return (
    <div className="flex flex-wrap justify-between gap-4 p-4 text-white z-50">
      
      <FilterMenu 
        isOpen={false} 
        onSelectFilters={onSelectFilters}
        clients={formDropDownData.clients}
        btcCtc={formDropDownData.btcCtc}
        department={formDropDownData.toBeExecutedBy}
        showJobStatusFilter={showJobStatusFilter}
        showDepartmentFilter={showDepartmentFilter}
      />
      <SortMenu 
        isOpen={false} 
      />
      <ActionMenu 
          isOpen={false}  
          showApproveRejectActions={showApproveRejectActions} 
          employeeEmail={employeeEmail}
          //this has been directly checked as we only need this prop from one component
          page={page}
          showReassignOption={showReassignOption}
      />
    </div>
  );
};

export default MainMenu

