import { useState } from 'react';

export const usePagination = (key, totalJobs, jobsPerPage = 10, pagesToShow = 3) => {
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem(key);
    return savedPage ? parseInt(savedPage, 10) : 1;
  });

  const totalPages = Math.ceil(totalJobs / jobsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        localStorage.setItem(key, nextPage);
        return nextPage;
      });
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const prevPageNum = prevPage - 1;
        localStorage.setItem(key, prevPageNum);
        return prevPageNum;
      });
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    localStorage.setItem(key, page);
  };

  const getPageNumbers = () => {
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  return {
    currentPage,
    totalPages,
    handleNextPage,
    handlePreviousPage,
    handlePageClick,
    getPageNumbers,
  };
};
