
import Navbar from './Navbar'
import { Outlet } from "react-router-dom"

const JobBagLayout = () => {
    return (
      <>
        <Navbar/>
        <Outlet/>
      </>
    )
  }

export default JobBagLayout

