//local
// const API_BASE_URL = "http://127.0.0.1:5000/api"

//digital ocean
// const API_BASE_URL = "http://143.110.176.161/api"

//domain name digital ocean (development)
const API_BASE_URL = "https://jobbagadmin.ndprojects.co.in/api"

export const API_URLS = {
    'EMPLOYEE_LOGIN':`${API_BASE_URL}/authentication/employee_login`,
    'FORM_DROP_DOWN_DATA': `${API_BASE_URL}/helper/form_drop_down_data`,
    'SUBMIT_JOB_FORM': `${API_BASE_URL}/job/raise_job`,
    'JOBS_FIRST_LEVEL': `${API_BASE_URL}/job/jobs_first_level`,
    'JOBS_SECOND_LEVEL': `${API_BASE_URL}/job/jobs_second_level`,
    'JOBS_THIRD_LEVEL': `${API_BASE_URL}/job/jobs_third_level`,
    'CHANGE_JOB_STATUS': `${API_BASE_URL}/job/change_job_status`,
    'CHANGE_STATUS_FINANCE': `${API_BASE_URL}/job/change_status_finance`,
    'JOB_HISTORY': `${API_BASE_URL}/comments/job_history`,
    'JOBS_BY_STATUS':  `${API_BASE_URL}/job/jobs_by_status`,
    'EMPLOYEE_DROP_DOWN_DATA': `${API_BASE_URL}/helper/employee_drop_down_data`,
    'RE_ASSIGN_JOBS': `${API_BASE_URL}/job/re_assign_jobs`,
    'JOBS_COUNT': `${API_BASE_URL}/job/job_count`,
    'SEARCH_JOBS': `${API_BASE_URL}/job/search_jobs_by_code`
}
export const HEADER_TYPE = {"Content-Type": "application/json"}

export const MONTH_LABELS = ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I', 'J', 'K', 'L']

export const EMPLOYEE_LEVELS = {
    '1': 'Normal Employee',
    '2': 'Approver Level 1',
    '3': 'Approver Level 2',
    '4': 'Admin'
}

export const APPROVAL_STATUSES = {
    '1': 'Approval Pending',
    '2': 'Approved',
    '3': 'Rejected',
    '4': 'Editing Required',
}

export const JOB_STATUSES = {
    '1': 'Raised',
    '2': 'Completed',
    '3': 'To Be Billed',
    '4': 'Billed But Not Payed',
    '5': 'Payment Completed'
}

	
