import { useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';

// Sort Menu Component
const SortMenu = ({ isOpen}) => {
  const [menuOpen, setMenuOpen] = useState(isOpen);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    // onToggle(); (prop removed from main menu)
  };

  const handleSort = () => {
    setMenuOpen(false)
  }

  return (
    <div className="relative flex-1 z-20">
      <div className="flex items-center justify-between p-4 bg-gray-800 rounded text-sm md:text-base">
        <span>Sort By</span>
        <FaEllipsisV onClick={toggleMenu} className="cursor-pointer text-xs md:text-base" />
      </div>
      
      {menuOpen && (
        <div className="absolute right-0 top-full mt-2 w-48 bg-gray-800 rounded shadow-lg max-w-full sm:w-56">
          <ul className="text-sm text-white">
            <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer" onClick={handleSort}>Option 1</li>
            <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer" onClick={handleSort}>Option 2</li>
            <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer" onClick={handleSort}>Option 3</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SortMenu;
