import { usePagination } from '../../hooks/usePagination'; // Import the pagination hook
import { useFetchJobsByStatus } from '../../hooks/useFetchJobsByStatus';
import JobTile from '../common/JobTile';
import ClipLoader from 'react-spinners/ClipLoader';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useFilterContext } from '../../contexts/FilterContext';
import { useAuthenticationContext } from '../../contexts/AuthenticationContext';
import { JOB_STATUSES } from '../../configuration/constants';
import { useEffect, useState } from 'react';

const DepartmentJobsSection = ({ isDepartmentJobsOpen, toggleDepartmentJobs }) => {
  const { employeeAuthenticationData } = useAuthenticationContext();
  const { selectedFilters, filterJobs } = useFilterContext();

  const jobsPerPage = 10;

  // Default to 0 for totalJobs until it is fetched
  const [totalJobs, setTotalJobs] = useState(0);

  // Use the usePagination hook, passing the totalJobs after fetching data
  const { currentPage, totalPages, handleNextPage, handlePreviousPage, handlePageClick, getPageNumbers } = usePagination('currentPageDepartmentSection', totalJobs, jobsPerPage);

  const params = employeeAuthenticationData
    ? {
        jobStatusName: JOB_STATUSES['1'],
        employeeLevel: employeeAuthenticationData.levelData.name,
        teamName: employeeAuthenticationData.teamData.name,
        teamId: employeeAuthenticationData.teamData.id,
        employeeEmail: employeeAuthenticationData.email,
        approvalStatusId: 1,
        page: currentPage, // Correctly assigning currentPage here
        limit: jobsPerPage,
        raisedBySection: 'Department Jobs Section',
      }
    : null;

  const {
    jobs: departmentJobs,
    loading: departmentJobsSectionLoading,
    error: departmentJobsSectionError,
    totalJobs: fetchedTotalJobs,
  } = useFetchJobsByStatus(params);

  // Update the totalJobs after the data is fetched
  useEffect(() => {
    if (fetchedTotalJobs !== totalJobs) {
      setTotalJobs(fetchedTotalJobs);
    }
  }, [fetchedTotalJobs, totalJobs]);

  const filteredJobs = filterJobs(departmentJobs, selectedFilters);

  return (
    <div className="my-6">
      <button
        onClick={toggleDepartmentJobs}
        className="flex items-center w-full p-4 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none"
      >
        <span className="text-lg font-semibold mr-4">Department Jobs</span>
        <span className="ml-auto">
          {isDepartmentJobsOpen ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>

      {isDepartmentJobsOpen && (
        <div className="mt-4">
          {departmentJobsSectionLoading ? (
            <div className="flex justify-center items-center h-32">
              <ClipLoader color="#ffffff" loading={departmentJobsSectionLoading} size={50} />
            </div>
          ) : departmentJobsSectionError ? (
            <div className="text-center text-red-500">Error: {departmentJobsSectionError}</div>
          ) : (
            <>
              {/* Render current jobs */}
              {filteredJobs.length === 0 ? (
                <div className="text-center text-gray-400">No jobs currently</div>
              ) : (
                filteredJobs.map((job) => (
                  <JobTile key={job.id} job={job} />
                ))
              )}

              {/* Pagination controls */}
              <div className="flex justify-center mt-4 space-x-2">
                {/* Previous arrow */}
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="text-xl text-gray-300 disabled:text-gray-500"
                >
                  <AiOutlineArrowLeft />
                </button>

                {/* Page numbers */}
                {getPageNumbers().map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageClick(pageNumber)}
                    className={`px-2 ${
                      currentPage === pageNumber
                        ? 'underline text-neon-blue'
                        : 'text-gray-300'
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}

                {/* Next arrow */}
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="text-xl text-gray-300 disabled:text-gray-500"
                >
                  <AiOutlineArrowRight />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DepartmentJobsSection;


