import { useQuery } from '@tanstack/react-query';
import { commonPostRequest } from '../api/CommonRequests';

export const useFetchJobsByStatus = (params) => {
  const {
    data,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['fetchJobsByStatus', params],
    queryFn: async () => {
    const responseBody = await commonPostRequest(params, 'JOBS_BY_STATUS');
      if (!responseBody.success) {
        throw new Error(responseBody.errorMessage);
      }
      return {
        jobs: responseBody.data.jobs,
        total: responseBody.data.total,
      };
    },
    keepPreviousData: true,
    enabled: !!params,
  });

  return {
    jobs: data?.jobs || [],
    loading: isLoading,
    error: error?.message || null,
    totalJobs: data?.total || 0,
  };
};



 //used in RaisedJobs page (MyJobsSection) in case of Normal Employee and Approver Level 1(applicable to both general and finance)
    //used in RaisedJobs page (DepartmentJobsSection) in case of Approver Level 1 (applicable to both general and finance)
    //and also in case of Approver Level 2 finance (since Approver Level 2 of finance can only see finance department jobs and the jobs Approver Level 2 raises are marked approved by default)
    //used in RaisedJobs page (AllJobsSection) in case of Approver Level 3 general and Admin 

    //Note:
    //myJobs is not needed for Approver Level 2 and Admin as their jobs are automatically approved
    //however Admin can mark the jobs raised by Approver Level 2 as rejected in the Approved Jobs page then the jobs would move to RejectedJobs page