const CustomDropDown = ({ labelName, value, setValue, dropDownOptions = [],excludeOption = null }) => {
    return (
        <div className="mb-4">
            <label className="block text-neon-blue mb-2">{labelName}</label>
            <select
                className="w-full bg-gray-700 border border-gray-600 rounded px-3 py-2 text-neon-blue"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                required
            >
                <option value="">--Select {labelName}--</option>
                {dropDownOptions
                .filter(option => option.name !== excludeOption) // Filter out the option to be excluded
                .map(option => (
                    <option key={option.id} value={option.id}>
                    {/* {labelName === "Client" ? option.code : option.name} */}
                        {option.name}
                    </option>
                ))
                }

            </select>
        </div>
    );
}

export default CustomDropDown;
