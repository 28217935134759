// hooks/useChangeJobStatus.js
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { commonPostRequest } from '../api/CommonRequests';
import { showAlert } from '../utils/helper';
import { JOB_STATUSES } from '../configuration/constants';

export const useChangeJobStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData) => {
      let urlKey = '';
      const jobStatusName = formData.get('jobStatusName')

      if (
        jobStatusName === JOB_STATUSES['4'] ||
        jobStatusName === JOB_STATUSES['5']
      ){
        urlKey = 'CHANGE_STATUS_FINANCE';
        console.log('request finacne',formData)
      } else {
        urlKey = 'CHANGE_JOB_STATUS';
        console.log('request not finacne',formData)
      }

      const responseBody = await commonPostRequest(formData, urlKey);
      if (responseBody.success) {
        return responseBody;
      } else {
        throw new Error(responseBody.errorMessage || 'An error occurred');
      }
    },
    onSuccess: (data) => {
      showAlert('success', 'Success', data.data.message);
      // Invalidate all queries that start with 'fetchJobsByStatus'
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'fetchJobsByStatus',
      });
    },
    onError: (error) => {
      showAlert('error', 'Error', error.message || 'An error occurred');
    },
  });
};
