import { useEffect, useState } from "react";
import { useAPIContext } from "../contexts/APIContext";
import CustomDropDown from '../components/Inputs/CustomDropDown';
import CustomInput from "../components/Inputs/CustomInput";
import { getJobCode } from '../utils/helper';
import { useAuthenticationContext } from '../contexts/AuthenticationContext';
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner

const JobForm = () => {
    const { formDropDownData, fetchFormDropDownData, submitJobForm, loading, error } = useAPIContext();
    const { employeeAuthenticationData } = useAuthenticationContext();

    // Drop down menu variables
    const [clientId, setClientId] = useState('');
    const [sbuId, setSbuId] = useState('');
    const [billingTypeId, setBillingTypeId] = useState('');
    const [frequencyTypeId, setFrequencyTypeId] = useState('');
    const [toBeExecutedById, setToBeExecutedById] = useState('');
    const [btcCtc, setBtcCtc] = useState('');

    // Other form data (in 'job' table)
    const [jobSubject, setJobSubject] = useState('');
    const [jobSummary, setJobSummary] = useState('');
    const [closureExpectedBy, setClosureExpectedBy] = useState('');
    const [billingAmount, setBillingAmount] = useState('');

    // Other form data (in 'comments' table)
    const [attachment, setAttachment] = useState(null);
    const [comments, setComments] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            await fetchFormDropDownData();
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
                <ClipLoader color="#ffffff" loading={loading} size={150} />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        
        // Append the form data (normal fields)
        formData.append('raisedByName', employeeAuthenticationData.name);
        formData.append('raisedByLevelId', employeeAuthenticationData.levelData.id);
        formData.append('raisedBySbuId', sbuId);
        formData.append('clientId', clientId);
        formData.append('jobSubject', jobSubject);
        formData.append('jobSummary', jobSummary);
        formData.append('billingTypeId', billingTypeId);
        formData.append('frequencyTypeId', frequencyTypeId);
        formData.append('closureExpectedBy', closureExpectedBy);
        formData.append('toBeExecutedById', toBeExecutedById);
        formData.append('billingAmount', billingAmount);
        formData.append('btcCtc', btcCtc);
        formData.append('jobCode', getJobCode(sbuId, clientId, formDropDownData.sbus, formDropDownData.clients));
        formData.append('raisedByEmail', employeeAuthenticationData.email);
        formData.append('automaticApproval', employeeAuthenticationData.levelData.name === "Admin" || employeeAuthenticationData.levelData.name === "Approver Level 2");
        formData.append('comments', comments);
        formData.append('logStatement', `${employeeAuthenticationData.name} raised this job`);
    
        if (attachment) {
            formData.append('attachment', attachment); 
        }
    
        submitJobForm(formData);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white p-4">
            <form onSubmit={handleSubmit} className="bg-gray-800 p-6 w-full max-w-md mx-4 mt-4">
                <h1 className="text-2xl font-bold mb-4 text-neon-blue">Job Form</h1>

                {/* Client DropDown */}
                <CustomDropDown
                    labelName={'Client'} 
                    value={clientId} 
                    setValue={setClientId} 
                    dropDownOptions={formDropDownData.clients}
                />
                
                {/* Sbu DropDown */}
                <CustomDropDown
                    labelName={'Sbu'} 
                    value={sbuId} 
                    setValue={setSbuId} 
                    dropDownOptions={formDropDownData.sbus}
                />

                {/* Billing Type DropDown */}
                <CustomDropDown
                    labelName={'Billing Type'} 
                    value={billingTypeId} 
                    setValue={setBillingTypeId} 
                    dropDownOptions={formDropDownData.billingTypes}
                />

                {/* To Be Executed By DropDown */}
                <CustomDropDown
                    labelName={'To Be Executed By'} 
                    value={toBeExecutedById} 
                    setValue={setToBeExecutedById} 
                    dropDownOptions={formDropDownData.toBeExecutedBy}
                    excludeOption={'Full SBU'}
                />

                {/* Job Frequency DropDown */}
                <CustomDropDown
                    labelName={'Job Frequency'} 
                    value={frequencyTypeId} 
                    setValue={setFrequencyTypeId} 
                    dropDownOptions={formDropDownData.frequencyTypes}
                />

                {/* BTC/CTC dropdown */}
                <CustomDropDown
                    labelName={'BTC/CTC'} 
                    value={btcCtc} 
                    setValue={setBtcCtc} 
                    dropDownOptions={formDropDownData.btcCtc}
                />

                {/* Closure Expected By */}
                <CustomInput
                    labelName={"Closure Expected By"}
                    type="date"
                    fieldName={closureExpectedBy}
                    setFieldName={setClosureExpectedBy}
                />
                
                {/* Job Subject */}
                <CustomInput
                    labelName={"Job Subject"}
                    type="text"
                    fieldName={jobSubject}
                    setFieldName={setJobSubject}
                />

                {/* Job Summary */}
                <CustomInput
                    labelName={"Job Summary"}
                    type="textarea"
                    fieldName={jobSummary}
                    setFieldName={setJobSummary}
                />

                {/* Billing Amount */}
                <CustomInput
                    labelName={"Billing Amount"}
                    type="number"
                    fieldName={billingAmount}
                    setFieldName={setBillingAmount}
                />

                <input 
                    type='file'
                    onChange={(event) => setAttachment(event.target.files[0])}
                />

                {/* Comments */}
                <CustomInput
                    labelName={"Comments"}
                    type="textarea"
                    fieldName={comments}
                    setFieldName={setComments}
                />

                <button
                    type="submit"
                    className="w-full bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded transition duration-200 transform hover:scale-105 hover:shadow-lg"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default JobForm;
