import { createContext, useContext, useState, useEffect } from 'react';

const AuthenticationContext = createContext();

export const useAuthenticationContext = () => useContext(AuthenticationContext);

const AuthenticationProvider = ({ children }) =>  {
  //data about the logged in user obtained based on email ans password
  //whereever {} is there it means id and name both are returned (ie teamData.name and teamData.id)
  const [employeeAuthenticationData, setEmployeeAuthenticationData] = useState({
    isAuthenticated: false,
    name: '',
    email: '',
    teamData: {},
    sbuData: {},
    levelData: {},
  });


  //if stored employee is there then set employeeAuthenticationData
  useEffect(() => {
    const storedEmployee = localStorage.getItem('storedEmployee');
    if (storedEmployee) {
      setEmployeeAuthenticationData({
        isAuthenticated: true,
        ...JSON.parse(storedEmployee),
      });
    }
  }, []);

  //set stored employee when login function is called from EmployeeLogin
  const employeeLogin = (employeeData) => {
    localStorage.setItem('storedEmployee', JSON.stringify(employeeData));
    setEmployeeAuthenticationData({
      isAuthenticated: true,
      ...employeeData,
    });
  }

  //employeeLogout called from Navbar
  const employeeLogout = () => {
    //remove the stored 
    localStorage.removeItem('storedEmployee');

    //reset the current page for all pages where pagination is implemented to 1
    localStorage.setItem('currentPageRejected', 1)
    localStorage.setItem('currentPageApproved', 1)
    localStorage.setItem('currentPageMyJobsSection',1)
    localStorage.setItem('currentPageDepartmentSection',1)
    localStorage.setItem('currentPageAllJobsSection',1)
    localStorage.setItem('currentPageToBeAssigned',1)
    localStorage.setItem('currentPageToBeBilled',1)
    localStorage.setItem('currentPagePaymentPending',1)
    localStorage.setItem('currentPagePaymentComplete',1)
    localStorage.setItem('currentPageAllJobs',1)

    //reset employeeAuthenticationData to initial values
    setEmployeeAuthenticationData({
      isAuthenticated: false,
      name: '',
      email: '',
      teamData: {},
      sbuData: {},
      levelData: {},
    });
    

  }

  return (
    <AuthenticationContext.Provider value={{ employeeAuthenticationData, employeeLogin, employeeLogout }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationProvider;
