import { useState, useEffect } from 'react';
import { useAuthenticationContext } from '../contexts/AuthenticationContext';
import { useFilterContext } from '../contexts/FilterContext';
import MainMenu from '../components/Menus/MainMenu';
import JobTile from '../components/common/JobTile';
import { useJobUtils } from '../hooks/useJobUtils';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useFetchJobsByStatus } from '../hooks/useFetchJobsByStatus';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import ClipLoader from "react-spinners/ClipLoader";

const AllJobs = () => {
  const { employeeAuthenticationData } = useAuthenticationContext();
  const { selectedFilters, setSelectedFilters, filterJobs } = useFilterContext();
  const { checkCondition } = useJobUtils();
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Pagination state
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPageAllJobs');
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const jobsPerPage = 10;
  const pagesToShow = 3;

  const params = employeeAuthenticationData
    ? {
        jobStatusName: 'All Jobs',
        employeeLevel: employeeAuthenticationData.levelData.name,
        teamName: employeeAuthenticationData.teamData.name,
        teamId: employeeAuthenticationData.teamData.id,
        employeeEmail: employeeAuthenticationData.email,
        page: currentPage,
        limit: jobsPerPage, 
      }
    : null;

  const {
    jobs: allJobs,
    loading: fetchJobsLoading,
    error: fetchJobsError,
    totalJobs,
  } = useFetchJobsByStatus(params);

  const totalPages = Math.ceil(totalJobs / jobsPerPage);
  const filteredJobs = filterJobs(allJobs, selectedFilters);

  // Handler for moving to the next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        localStorage.setItem('currentPageAllJobs', nextPage);
        return nextPage;
      });
    }
  };

  // Handler for moving to the previous page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const prevPageNum = prevPage - 1;
        localStorage.setItem('currentPageAllJobs', prevPageNum);
        return prevPageNum;
      });
    }
  };

  // Handler for moving to a specific page
  const handlePageClick = (page) => {
    setCurrentPage(page);
    localStorage.setItem('currentPageAllJobs', page);
  };

  // Calculate the page numbers to show dynamically based on the current page
  const getPageNumbers = () => {
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  
  useEffect(() => {
    return () => {
      setSelectedFilters([]); 
    };
  }, [navigate, setSelectedFilters]);

  return (
    <div className="p-4 bg-gray-900 text-white min-h-screen">
      <MainMenu
        onSelectFilters={setSelectedFilters}
        showJobStatusFilter={true}
        showDepartmentFilter={checkCondition('showDepartmentFilter')}
        showApproveRejectActions={checkCondition('showApproveRejectActions')}
      />

      <div className="mt-4">
        {fetchJobsLoading ? (
          <div className="flex justify-center items-center h-32">
            <ClipLoader color="#ffffff" loading={fetchJobsLoading} size={50} />
          </div>
        ) : fetchJobsError ? (
          <div className="text-center text-red-500">Error: {fetchJobsError}</div>
        ) : (
          <>
            {filteredJobs.map((job) => (
              <JobTile key={job.id} job={job} />
            ))}

            <div className="flex justify-center mt-4 space-x-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="text-xl text-gray-300 disabled:text-gray-500"
              >
                <AiOutlineArrowLeft />
              </button>

              {getPageNumbers().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageClick(pageNumber)}
                  className={`px-2 ${currentPage === pageNumber ? 'underline' : 'text-gray-300'}`}
                >
                  {pageNumber}
                </button>
              ))}

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="text-xl text-gray-300 disabled:text-gray-500"
              >
                <AiOutlineArrowRight />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AllJobs;

