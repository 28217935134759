import { createContext, useContext, useState, useMemo } from 'react';

const FilterContext = createContext();

export const useFilterContext = () => useContext(FilterContext);

const FilterProvider = ({ children }) => {
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [sortBy, setSortBy] = useState("");
    const [actions, setActions] = useState("");


    const filterJobs = (jobs, filters = []) => {
        console.log('filters: ', filters);
    
        // Group filters by type
        const groupedFilters = filters.reduce((acc, filter) => {
            if (!acc[filter.type]) acc[filter.type] = [];
            acc[filter.type].push(filter.value);
            return acc;
        }, {});
    
        return jobs.filter((job) => {
            return Object.keys(groupedFilters).every(filterType => {
                const filterValues = groupedFilters[filterType];
    
                if (filterType === "Job Status") {
                    // Handle the different cases for Job Status
                    return filterValues.some(value => {
                        if (value === "Raised") return job.jobPhase.name === "Raised" && job.approvalStatus.name === "Approval Pending";
                        if (value === "Approved" || value === "Rejected") return job.approvalStatus.name === value && job.jobPhase.name === "Raised";
                        return job.jobPhase.name === value;
                    });
                }
    
                if (filterType === "Client") {
                    // Check if any of the selected clients match
                    return filterValues.includes(job.client.name);
                }
    
                if (filterType === "BTC/CTC") {
                    // Check if any of the selected BTC/CTC match
                    return filterValues.includes(job.btcCtc);
                }
    
                if (filterType === "Department") {
                    // Check if any of the selected departments match
                    return filterValues.includes(job.raisedByTeam.name);
                }
    
                return true;
            });
        });
    };
    

    const value = useMemo(() => ({
        selectedFilters,
        setSelectedFilters,
        sortBy,
        setSortBy,
        actions,
        setActions,
        filterJobs,
    }), [selectedFilters, sortBy, actions]);

    return (
        <FilterContext.Provider value={value}>
            {children}
        </FilterContext.Provider>
    );
};

export default FilterProvider;

