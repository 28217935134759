import React, { useState, useMemo, useCallback } from 'react';

function ItemList() {
  // State for the list of items and the search term
  const [items] = useState(['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry']);
  const [searchTerm, setSearchTerm] = useState('');
  const [counter, setCounter] = useState(0);

  // useMemo to memoize the filtered list of items
  const filteredItems = useMemo(() => {
    console.log('Filtering items...');
    return items.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [items, searchTerm]);

  // useCallback to memoize the increment function
  const incrementCounter = useCallback(() => {
    setCounter(prevCounter => prevCounter + 1);
  }, []);

  return (
    <div>
      <h1>Item List with Counter</h1>
      
      {/* Input for filtering items */}
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search items..."
      />

      {/* Button to increment the counter */}
      <button onClick={incrementCounter}>
        Increment Counter: {counter}
      </button>

      {/* Display the filtered items */}
      <ul>
        {filteredItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default ItemList;


