import { useState } from 'react';
import { useActionContext } from '../../contexts/ActionContext';
import { useAuthenticationContext } from '../../contexts/AuthenticationContext';
import { APPROVAL_STATUSES, JOB_STATUSES } from '../../configuration/constants';
import { useChangeJobStatus } from '../../hooks/useChangeJobStatus'; // Import the custom hook
import { ClipLoader } from 'react-spinners'; // Import the spinner component from react-spinners

const ActionsModal = () => {
    const { modalData, closeModal, setSelectedJobs } = useActionContext();
    const { actionName, jobCodes,employeeName = '',currentBillingAmount } = modalData;
    const { employeeAuthenticationData } = useAuthenticationContext();
    const changeJobStatus = useChangeJobStatus(); // Use the custom hook

    const [billingAmount, setBillingAmount] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [tax, setTax] = useState('');
    const [invoice, setInvoice] = useState('');
    const [comments, setComments] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // State to control the spinner

    console.log('modal open: ',)

    const getLogStatement = () => {
        // Solve the NaN error as the changed by is going as NaN
        if (parseFloat(currentBillingAmount) - parseFloat(billingAmount) === 0) {
            return `${employeeAuthenticationData.name} has billed the job`;
        } else if (parseFloat(currentBillingAmount) > parseFloat(billingAmount)) {
            return `${employeeAuthenticationData.name} has decreased the billing amount by ${
                parseFloat(currentBillingAmount) - parseFloat(billingAmount)
            }`;
        } else {
            return `${employeeAuthenticationData.name} has increased the billing amount by ${
                parseFloat(billingAmount) - parseFloat(currentBillingAmount)
            }`;
        }
    };

    const handleConfirm = () => {
        const formData = new FormData();  // Create a FormData object
    
        // Append common form data fields
        formData.append('modifiedByEmail', employeeAuthenticationData.email);
        formData.append('comments', comments);
        formData.append('jobStatusName', actionName);
    
        // Append specific fields based on the action
        if (
            actionName === APPROVAL_STATUSES['2'] ||
            actionName === APPROVAL_STATUSES['3'] ||
            actionName === JOB_STATUSES['2']
        ) {
            formData.append('jobCodes', JSON.stringify(jobCodes));
            formData.append('logStatement', `${employeeAuthenticationData.name} ${actionName} this job`);
            if (attachment) {
                formData.append('attachment', attachment);
            }
        } else if (actionName === JOB_STATUSES['4']) {
            formData.append('jobCodes', JSON.stringify(jobCodes));
            formData.append('billingAmount', billingAmount);
            formData.append('tax', tax);
            formData.append('totalAmount', totalAmount);
            formData.append('invoice', invoice);
            formData.append('logStatement', getLogStatement());
            if (attachment) {
                formData.append('attachment', attachment);
            }
        } else if (actionName === JOB_STATUSES['5']) {
            formData.append('jobCodes', JSON.stringify(jobCodes));
            formData.append('logStatement', `${employeeAuthenticationData.email} marked payment as complete`);
            if (attachment) {
                formData.append('attachment', attachment);
            }
        }

        setIsLoading(true); // Show spinner when the request starts
        changeJobStatus.mutate(formData, {
            onSuccess: () => {
                setSelectedJobs([]);
                closeModal();
                setIsLoading(false); // Hide spinner on success
            },
            onError: () => {
                setIsLoading(false); // Hide spinner on error
            }
        });
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg text-white mx-4 sm:mx-auto mt-20 w-full sm:w-2/3 lg:w-1/2 xl:w-1/3 max-w-screen-lg max-h-[90vh] overflow-y-auto relative">
                <h2 className="text-lg font-semibold mb-4 text-center sm:text-left">{actionName}</h2>

                {/* Scrollable content for "Billed But Not Payed" */}
                <div className={actionName === 'Billed But Not Payed' ? 'max-h-[60vh] overflow-y-auto' : ''}>
                    {actionName === 'Billed But Not Payed' && (
                        <>
                            <div className="mb-4">
                                <label htmlFor="billingAmount" className="block text-sm font-medium mb-2">Billing Amount</label>
                                <input
                                    id="billingAmount"
                                    type="number"
                                    placeholder={currentBillingAmount}
                                    className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-neon-blue"
                                    value={billingAmount}
                                    onChange={(e) => setBillingAmount(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="totalAmount" className="block text-sm font-medium mb-2">Total Amount</label>
                                <input
                                    id="totalAmount"
                                    type="number"
                                    className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-neon-blue"
                                    value={totalAmount}
                                    onChange={(e) => setTotalAmount(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="tax" className="block text-sm font-medium mb-2">Tax</label>
                                <input
                                    id="tax"
                                    type="number"
                                    className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-neon-blue"
                                    value={tax}
                                    onChange={(e) => setTax(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="invoice" className="block text-sm font-medium mb-2">Invoice</label>
                                <input
                                    id="invoice"
                                    type="text"
                                    className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-neon-blue"
                                    value={invoice}
                                    onChange={(e) => setInvoice(e.target.value)}
                                />
                            </div>
                        </>
                    )}

                    <div className="mb-4">
                        <label htmlFor="comments" className="block text-sm font-medium mb-2">Comments</label>
                        <textarea
                            id="comments"
                            className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2"
                            rows="4"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="attachment" className="block text-sm font-medium mb-2">Attachment</label>
                        <input
                            id="attachment"
                            type="file"
                            className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-neon-blue"
                            onChange={(event) => setAttachment(event.target.files[0])}
                        />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row justify-end">
                    <button onClick={closeModal} className="mb-2 sm:mb-0 sm:mr-2 px-4 py-2 bg-red-600 rounded hover:bg-red-700 w-full sm:w-auto">
                        Close
                    </button>
                    <button onClick={handleConfirm} className="px-4 py-2 bg-blue-600 rounded hover:bg-blue-700 w-full sm:w-auto">
                        Confirm
                    </button>
                </div>

                {/* Spinner Overlay */}
                {isLoading && (
                    <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
                        <ClipLoader color="#ffffff" size={50} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ActionsModal;

