import { useJobUtils } from '../../hooks/useJobUtils';
import { useActionContext } from '../../contexts/ActionContext';
import { useState, useEffect } from 'react';
import { useAPIContext } from '../../contexts/APIContext';
import { FaEllipsisV } from 'react-icons/fa';
import HistoryModal from '../modals/HistoryModal';
import DetailsModal from '../modals/DetailsModal';

const JobTile = ({ job, filterCondition = null, allCheckBoxesDisabled = false }) => {
    const { getJobOnCustomCondition, checkCondition, getText } = useJobUtils();
    const { selectedJobs, toggleJobSelection, setSelectedJobs } = useActionContext();
    const { fetchJobHistory, jobHistory, loading, error } = useAPIContext();

    const [menuOpen, setMenuOpen] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    useEffect(() => {
        // Cleanup function to reset selectedJobs when the component unmounts
        return () => {
            setSelectedJobs([]);
        };
    }, [setSelectedJobs]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const openHistoryModal = () => {
        fetchJobHistory({ jobCode: job.jobCode });
        setHistoryModalOpen(true);
    };

    const closeHistoryModal = () => {
        setHistoryModalOpen(false);
    };

    const openDetailsModal = () => {
        setDetailsModalOpen(true);
    };

    const closeDetailsModal = () => {
        setDetailsModalOpen(false);
    };

    const filteredJob = filterCondition !== null ? getJobOnCustomCondition(job, filterCondition) : job;

    if (!filteredJob) {
        return null;
    }

    const isSelected = selectedJobs.includes(job.jobCode);
    const allowApprovalStatusChange = checkCondition('allowApprovalStatusChange', job);
    const allowToAssignJobs = checkCondition('allowToAssignJobs', job);
    const allowToReassignJobs = checkCondition('allowToReassignJobs', job);

    const jobStatus = getText(job, 'jobStatus');

    return (
        <div className="p-4 bg-gray-800 rounded shadow-white transition duration-200 mb-4 flex items-center relative">
            {(allowApprovalStatusChange || allowToAssignJobs || allowToReassignJobs) && (!allCheckBoxesDisabled) && (
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleJobSelection(job.jobCode)}
                    className="mr-4"
                />
            )}
            <div className="flex-1">
                <h3 className="text-white text-xl font-semibold mb-2">{job.jobSubject}</h3>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">Job Code:</span> {job.jobCode}
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">Raised By:</span> {job.raisedByName}
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">Client:</span> {job.client.name}
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">BTC/CTC:</span> {job.btcCtc}
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">Billing Amount:</span> {job.billingAmount}
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">Created By:</span> {job.raisedByName}
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">Job Status:</span> {jobStatus}
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">To Be Billed By:</span> {job.toBeBilledBy} (for my convenience)
                </p>
                <p className="text-gray-300 mb-1 flex items-baseline">
                    <span className="font-bold w-32">Payment To Be Completed By</span> {job.paymentToBeCompletedBy} (for my convenience)
                </p>
            </div>
            <div className="absolute top-2 right-2">
                <FaEllipsisV onClick={toggleMenu} className="cursor-pointer text-white text-xl" />
                {menuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded shadow-lg z-10">
                        <div className="p-2 hover:bg-gray-600 cursor-pointer" onClick={() => { openDetailsModal(); setMenuOpen(false); }}>View Details</div>
                        <div className="p-2 hover:bg-gray-600 cursor-pointer" onClick={() => { openHistoryModal(); setMenuOpen(false); }}>History</div>
                    </div>
                )}
                {historyModalOpen && (
                    <HistoryModal
                        jobSubject={job.jobSubject}
                        jobHistory={jobHistory}
                        onClose={closeHistoryModal}
                        loading={loading}
                        error={error}
                    />
                )}
                {detailsModalOpen && (
                    <DetailsModal
                        job={job}
                        onClose={closeDetailsModal}
                        allowApprovalStatusChange={allowApprovalStatusChange}
                    />
                )}
            </div>
        </div>
    );
};

export default JobTile;
