import { FaEllipsisV } from 'react-icons/fa';
import { useActionContext } from '../../contexts/ActionContext';
import ActionsModal from '../modals/ActionsModal';
import { useState } from 'react';
// import { useAPIContext } from '../../contexts/APIContext';
import { APPROVAL_STATUSES,JOB_STATUSES } from '../../configuration/constants';
// import { useAuthenticationContext } from '../../contexts/AuthenticationContext';
import { showAlert } from '../../utils/helper';

const ActionsMenu = ({ isOpen, showApproveRejectActions = false, employeeEmail = null,page = null,showReassignOption = null }) => {
  const { performAction, isModalOpen } = useActionContext();
  const [menuOpen, setMenuOpen] = useState(isOpen);
  // const {fetchJobsByStatus,completedJobs,toBeBilledJobs,billedButNotPayedJobs,paymentCompleteJobs} = useAPIContext()
  // const {employeeAuthenticationData} = useAuthenticationContext()

  const handleActionClick = (actionName, email) => {
    console.log('action: ',actionName,email)
    if((actionName === 'Assign' || actionName === 'Re-Assign') && !email){
      console.log('null email')
      showAlert('warning','No Employee Selected','Please Select An Employee')
      return
    }
    performAction(actionName, email);
    toggleMenu()
    
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    // onToggle(); 
  };

  return (
    <div className="relative flex-1 z-20">
      <div className="flex items-center justify-between p-4 bg-gray-800 rounded text-sm md:text-base">
        <span>Actions</span>
        {/* Toggle the menu when clicking the icon */}
        <FaEllipsisV 
          onClick={toggleMenu} 
          className="cursor-pointer text-xs md:text-base" 
        />
      </div>
      
      {menuOpen && (
        <div className="absolute right-0 top-full mt-2 w-48 bg-gray-800 rounded shadow-lg max-w-full sm:w-56">
          <ul className="text-sm text-white">
            {showApproveRejectActions && (
              <>
                <li 
                  className="px-4 py-2 hover:bg-gray-700 cursor-pointer" 
                  onClick={() => handleActionClick(APPROVAL_STATUSES['2'])}
                >
                  Approve
                </li>
                <li 
                  className="px-4 py-2 hover:bg-gray-700 cursor-pointer" 
                  onClick={() => handleActionClick(APPROVAL_STATUSES['3'])}
                >
                  Reject
                </li>
              </>
            )}
            {page === 'ToBeAssignedJobs' && (
              <li 
                className="px-4 py-2 hover:bg-gray-700 cursor-pointer" 
                onClick={() => handleActionClick("Assign", employeeEmail)}
              >
                Assign
              </li>
            )}
            {showReassignOption && (
              <li 
                className="px-4 py-2 hover:bg-gray-700 cursor-pointer" 
                onClick={() => handleActionClick("Re-Assign", employeeEmail)}
              >
                Re-Assign
              </li>
            )}
            <li className="px-4 py-2 hover:bg-gray-700 cursor-pointer">
              Dummy Action
            </li>
          </ul>
        </div>
      )}

      {isModalOpen && <ActionsModal />}
    </div>
  );}

export default ActionsMenu