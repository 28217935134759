import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';

import ProtectedAccess from './components/common/ProtectedAccess';
import JobBagLayout from './components/common/JobBagLayout';

import EntryPage from './pages/EntryPage';
import EmployeeLogin from './pages/EmployeeLogin';
import MainPage from './pages/MainPage';
import JobForm from './pages/JobForm';

import Test from './pages/Test';

import RaisedJobs from './pages/RaisedJobs';
import ApprovedJobs from './pages/ApprovedJobs';
import RejectedJobs from './pages/RejectedJobs';
import AllJobs from './pages/AllJobs';
import SearchJobs from './pages/SearchJobs';

import ToBeAssignedJobs from './pages/ToBeAssignedJobs';
import ToBeBilledJobs from './pages/ToBeBilledJobs';
import PaymentCompleted from './pages/PaymentCompleted';
import PaymentPending from './pages/PaymentPending';

//

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* / is the route for the EntryPage which is shown when the application first starts */}
          <Route path='/' element={<EntryPage />} />
          {/* /employee_login is the login form with the email and password*/}
          <Route path='/employee_login' element={<EmployeeLogin />} />
          {/* /test is just to test something out in the application such as attachment upload*/}
          {/*can be removed as it does not contribute to the application logic */}
          <Route path='/test' element={<Test />} />

          {/*only logged in employees can access the links with the prefix /job_bag */}
          <Route
            path='/job_bag'
            element={
              <ProtectedAccess> {/*only authenticated employees can acesss */}
                <JobBagLayout /> {/* to render navbar and child component */}
              </ProtectedAccess>
            }
          > 
             {/*Main Page showing the count (of jobs) and containing the links to the pages under General and Finance */}
             <Route path='main_page' element={<MainPage />} />
         

            {/* General i.e section containing that is opened by default on MainPage when a non finance employee logs in contains links to these pages*/}
            <Route path='job_form' element={<JobForm />} />
            <Route path='raised_jobs' element={<RaisedJobs />} />
            <Route path='approved_jobs' element={<ApprovedJobs />} />
            <Route path='rejected_jobs' element={<RejectedJobs />} />
            
              {/* General i.e section containing that is opened by default on MainPage when non finance employee logs in contains links to these pages*/}
            <Route path='to_be_assigned_jobs' element={<ToBeAssignedJobs />} />
            <Route path='to_be_billed_jobs' element={<ToBeBilledJobs />} />
            <Route path='payment_completed' element={<PaymentCompleted />} />
            <Route path='payment_pending' element={<PaymentPending />} />

            {/*Common*/}
            <Route path='search_jobs' element={<SearchJobs/>}/>
            <Route path='all_jobs' element={<AllJobs />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

