import { HEADER_TYPE, API_URLS } from '../configuration/constants'

export const commonGetRequest = async (requestUrlKey) => {
    try {
        const serverResponse = await fetch(API_URLS[requestUrlKey]);
        if (serverResponse.ok) {
            const responseData = await serverResponse.json();
            return { success: true, data: responseData };
        }

        return { success: false, errorMessage: 'HTTP error or Unknown Error' };

    } catch (error) {
        return { success: false, errorMessage: error.message || 'A network error occurred', statusCode: 500 };
    }
};

//check for FormData is needed if attachments are used
export const commonPostRequest = async (requestBodyData, requestUrlKey) => {
    let headers = {};
    let body;

    // Check if requestBodyData is an instance of FormData
    if (requestBodyData instanceof FormData) {
        // FormData: No need to set Content-Type, let the browser handle it
        body = requestBodyData;
    } else {
        // JSON: Set Content-Type to application/json
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(requestBodyData);
    }

    try {
        const serverResponse = await fetch(API_URLS[requestUrlKey], {
            method: "POST",
            headers,  // Include headers dynamically
            body      // Set the body dynamically based on type (FormData or JSON)
        });

        if (serverResponse.ok) {
            const responseData = await serverResponse.json();
            return { success: true, data: responseData };
        } else {
            const error = await serverResponse.json();
            return { success: false, errorMessage: error.message || 'An error occurred', statusCode: serverResponse.status };
        }
    } catch (error) {
        return { success: false, errorMessage: error.message || 'A network error occurred', statusCode: 500 };
    }
};



export const commonPatchRequest = async (requestBodyData, requestUrlKey) => {
    try {
        const serverResponse = await fetch(API_URLS[requestUrlKey], {
            method: "PATCH",
            headers: HEADER_TYPE,
            body: JSON.stringify(requestBodyData)
        });

        if (serverResponse.ok) {
            const responseData = await serverResponse.json();
            return { success: true, data: responseData };
        } else {
            const error = await serverResponse.json();
            return { success: false, errorMessage: error.message || 'An error occurred', statusCode: serverResponse.status };
        }
    } catch (error) {
        return { success: false, errorMessage: error.message || 'A network error occurred', statusCode: 500 };
    }
};
