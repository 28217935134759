import { useState, useEffect } from 'react';
import { EMPLOYEE_LEVELS,APPROVAL_STATUSES,JOB_STATUSES } from "../configuration/constants";
import { useAuthenticationContext } from "../contexts/AuthenticationContext";

export function useJobUtils() {
    const { employeeAuthenticationData } = useAuthenticationContext();
    const [loggedInEmployee,setLoggedInEmployee] = useState(employeeAuthenticationData);

    useEffect(() => {
        setLoggedInEmployee(employeeAuthenticationData);
        // console.log('data: ',loggedInEmployee.levelData.name)
    }, [employeeAuthenticationData]); 

    
    const isFinanceEmployee = employeeAuthenticationData.teamData.name === "Finance"

    //department filter visible:
    // admin + general -> employee level 3 + finance no one
    const checkCondition = (context,job=null,additionalCondition=null) => {
        //here we need to take into consideration JOB_STATUS where applicable 
        //this is because we are making use of fetchJobs (which fetches according to employee level)
        if (context === "showDepartmentFilter") {
           return ((!isFinanceEmployee && loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['3']) || loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4'])

        } else if (context === "showApproveRejectActions") {
            return loggedInEmployee.levelData.name !== EMPLOYEE_LEVELS['1']
         
        } else if (context === "showMyJobsSection") {
            return loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['1'] || loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['2']
        } else if (context === "showDepartmentSection") {
            //approver level 1 is shown department jobs and approver level 1 and 2 in case of finance is shown department jobs
            return (loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['2'] || (loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['3'] && isFinanceEmployee))
           
        } else if (context === "showAllJobsSection") {
            //admin is shown all jobs and approver level 2 for general but not for finance
            //general approver leve 2 can see finance jobs(as they are a part of all jobs) but cannot approve it
            return ((loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4']) || (loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['3'] && !isFinanceEmployee))
        }else if(context === "allowApprovalStatusChange"){
            //ask about the second or after levelId null
            let generalJobCondition = (job.raisedByEmail !== loggedInEmployee.email) && 
            (job.raisedByLevelId < loggedInEmployee.levelData.id) &&(job.jobPhase.name === JOB_STATUSES['1']) &&
            ((job.approver.levelId === null) || (job.approver.levelId <= loggedInEmployee.levelData.id));

            //has to be finance or admin to approve/reject finance jobs
            let financeJobConditon = generalJobCondition && (isFinanceEmployee || loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4'])

            return job.raisedByTeam.name === "Finance" ? financeJobConditon : generalJobCondition
            
        }else if(context === 'showFinanceDropdown1'){
            
            return (isFinanceEmployee || loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4'])
        }else if(context === 'showFinanceDropdown2'){
            
            return ((isFinanceEmployee && loggedInEmployee.levelData.name !== EMPLOYEE_LEVELS['1']) || loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4'])
        }else if(context === 'showApprovedDropDown'){

            //anyone except for 1 can reassign for completion
            return (loggedInEmployee.levelData.name !== EMPLOYEE_LEVELS['1'])
        }
        
        else if(context === "allowToAssignJobs"){
            let financeEmployeeCondition = job.jobPhase.name === JOB_STATUSES['2'] && (loggedInEmployee.teamData.name === "Finance")
            let adminCondition =  job.jobPhase.name === JOB_STATUSES['2'] && loggedInEmployee.levelData.name === "Admin"

            return financeEmployeeCondition || adminCondition
        }else if(context === "canMarkAsComplete"){
            return  job.raisedByEmail === loggedInEmployee.email && !additionalCondition && job.approvalStatus.name === APPROVAL_STATUSES['2'] &&job.jobPhase.name === JOB_STATUSES['1'];

        }else if(context === 'allowToReassignJobs'){
            if(job.jobPhase.name === JOB_STATUSES['3'] || job.jobPhase.name === JOB_STATUSES['4']){
                if(isFinanceEmployee)
                return  (loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['2'] || loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['3'])
                else return loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4']
            }
            else if(job.jobPhase.name === JOB_STATUSES['1'] && job.approvalStatus.name === APPROVAL_STATUSES['2']){
                return (loggedInEmployee.levelData.name !== EMPLOYEE_LEVELS['1'])
            }
            
        }
        
      
        
        else if(context === "canBillJob"){
            return (job.toBeBilledBy === loggedInEmployee.email && job.jobPhase.name === JOB_STATUSES['3']) || (loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4'] &&  job.jobPhase.name === JOB_STATUSES['3']);
        }else if(context === "canCompletePayment"){
            return (job.paymentToBeCompletedBy === loggedInEmployee.email && job.jobPhase.name === JOB_STATUSES['4']) || 
            (loggedInEmployee.levelData.name === EMPLOYEE_LEVELS['4'] &&  job.jobPhase.name === JOB_STATUSES['4']) 
        }
    };

    const getJobOnCustomCondition = (job, condition) => {
        //here we have already checked the JOB_STATUS as we are using fetchJobByStatus()
          //(note: the right to actually do an action like change status,bill etc
          // is in checkCondition so no need to worry about it here)
        if (condition === "myJobsSection") {
            return (job.raisedByEmail === loggedInEmployee.email && job.approvalStatus.name === APPROVAL_STATUSES['1'] && job.jobPhase.name === JOB_STATUSES['1']) ? job : null;
        } 
        
        else if (condition === "departmentJobsSection") {
            return (job.raisedByEmail !== loggedInEmployee.email && job.approvalStatus.name === APPROVAL_STATUSES['1'] && job.jobPhase.name === JOB_STATUSES['1']) ? job : null;
        }else if (condition === "allJobsSection") {
            return (job.raisedByEmail !== loggedInEmployee.email && job.approvalStatus.name === APPROVAL_STATUSES['1'] && job.jobPhase.name === JOB_STATUSES['1']) ? job : null;
        }
 
        return null; 
    };

    const getText = (job,context) => {
        if(context === "jobStatus"){
            const approvalStatus =  job.approvalStatus.name === APPROVAL_STATUSES['2'] || job.approvalStatus.name === APPROVAL_STATUSES['3'] ?  `${job.approvalStatus.name} By ${job.approver.name}` : `Raised by ${job.raisedByName}`
            return job.jobPhase.name === JOB_STATUSES['1']  ? approvalStatus : job.jobPhase.name
        }
    } 

    const getCount = (jobs,phase,approvalStatus=null) => {
        let filteredJobs = []
        if(approvalStatus !== null){
            filteredJobs = jobs.filter(job => job.jobPhase.name === phase && job.approvalStatus.name === approvalStatus)
        }

        return filteredJobs.length
     
    
    }
    
    const sortByRaisedDateTimeOnly = (filteredJobs) => {
        const sortedJobs = filteredJobs.sort((a, b) => {
            const dateA = new Date(a.jobRaisedDateTime);
            const dateB = new Date(b.jobRaisedDateTime);
    
            return dateB - dateA;
        })
        return sortedJobs
    }

    const sortJobsByToBeBilledAndRaised = (filteredJobs) => {
        
        const sortedJobs = filteredJobs.sort((a, b) => {
            // Condition 1: jobs where to_be_billed_by == employeeAuthenticationData.email
            if (a.toBeBilledBy === employeeAuthenticationData.email && b.toBeBilledBy !== employeeAuthenticationData.email) {
                return -1;
            }
            if (a.toBeBilledBy !== employeeAuthenticationData.email && b.toBeBilledBy === employeeAuthenticationData.email) {
                return 1;
            }
    
            // Condition 2: jobs where raised_by_email == employeeAuthenticationData.email
            if (a.raisedByEmail === employeeAuthenticationData.email && b.raisedByEmail !== employeeAuthenticationData.email) {
                return -1;
            }
            if (a.raisedByEmail !== employeeAuthenticationData.email && b.raisedByEmail === employeeAuthenticationData.email) {
                return 1;
            }
    
            const dateA = new Date(a.jobRaisedDateTime);
            const dateB = new Date(b.jobRaisedDateTime);
    
            return dateB - dateA;
        });
        
        return sortedJobs

    }

    
    return { checkCondition,getJobOnCustomCondition,getText,getCount,sortJobsByToBeBilledAndRaised,sortByRaisedDateTimeOnly };
}

















