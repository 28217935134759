import { MONTH_LABELS } from "../configuration/constants"
import Swal from 'sweetalert2';

export function getJobCode(selectedSbuID, selectedClientId, sbuOptions, clientOptions) {
    const sbu = sbuOptions.find(option => option.id === parseInt(selectedSbuID));
    const client = clientOptions.find(option => option.id === parseInt(selectedClientId));
   
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0'); 
    const year = date.getFullYear() % 100;

    const currentMonth = MONTH_LABELS[date.getMonth()];

    return (sbu.code + client.code + `${day}${year}${currentMonth}`);
}


export function showAlert(type, title, text){
    Swal.fire({
        icon: type,
        title: title, 
        text: text,
        showConfirmButton: true,
        allowEscapeKey: false,
        timer: undefined
    });
};
